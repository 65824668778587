<mat-form-field appearance="outline" class="w-100">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <mat-select
        [formControl]="control"
        multiple
        (selectionChange)="onChangeItems($event)"
        [ngStyle]="{ 'height': selectedItems.length > 4 ? '110px' : 'auto' }" >
        <mat-select-trigger>
            <mat-chip-list>
                <mat-chip *ngFor="let option of selectedItems" [removable]="true" (removed)="removeOption(option)">
                    {{option.name }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>
        </mat-select-trigger>
        <mat-option *ngFor="let item of items" [value]="item.id">{{ item.name }}</mat-option>
    </mat-select>
</mat-form-field>
