
<main style="background-color: #ffffff; padding-top: 1rem; padding-bottom: 4rem;min-height: 100vh; display: flex; align-items: center; justify-content: center;">

  <div class="content w-100">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-xl-8 offset-xl-2">
          <div class="account-content">
            <div class="row align-items-center justify-content-center">
              <div class="col-12 col-lg-7 login-left">
                <img src="assets/img/auth/login-banner.png" class="img-fluid" alt="Login Banner">
              </div>
              <div class="col-12 col-lg-5 login-right">
                <form name="form" #f="ngForm" (ngSubmit)="f.form.valid && onSubmit()">
                  <div *ngIf="error" class="alert alert-danger" role="alert">{{error}}</div>
                  <div class="login-header">
                    <h3>ورود</h3>
                  </div>
                  <div>
                    <div class="form-group">
                      <input
                        [ngModel]="model.Mobile"
                        #Mobile="ngModel"
                        type="text"
                        class="form-control floating"
                        placeholder="{{'auth.mobile' | translate}}"
                        [ngClass]="{ 'is-invalid': f.submitted && Mobile.invalid }"
                        name="Mobile"
                        (ngModelChange)="mobileChange($event)"
                        required>
                      <div *ngIf="f.submitted && Mobile.invalid" class="invalid-feedback">
                        <div *ngIf="Mobile.errors.required">شماره همراه الزامی است.</div>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <input
                          [(ngModel)]="model.Password"
                          #Password="ngModel"
                          [type]="show? 'text' : 'password'"
                          class="form-control floating"
                          placeholder="{{'auth.password' | translate}}"
                          name="Password"
                          [ngClass]="{ 'is-invalid': f.submitted && Password.invalid }"
                          required
                          minlength="6">
                        <div class="input-group-append">
                          <a class="input-group-text" id="inputGroupAppend" (click)="onShowPasswordClicked()">
                            <i class="fa" [ngClass]="show? 'fa-eye-slash' : 'fa-eye'"></i>
                          </a>
                        </div>
                        <div *ngIf="f.submitted && Password.invalid" class="invalid-feedback">
                          <div *ngIf="Password.errors.required"> رمزعبور الزامی است.</div>
                          <div *ngIf="Password.errors.minlength"> رمزعبور باید حداقل 6 کاراکتر باشد.</div>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div class="mt-4">
                    <button class="btn btn-primary btn-block btn-lg login-btn" type="submit" [disabled]="loading">
                      <span
                        *ngIf="loading"
                        class="spinner-grow spinner-grow-sm text-light float-left"
                        role="status" aria-hidden="true">
                      </span>
                      <span>{{'auth.signIn' | translate }}</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</main>
