import { Injectable, Pipe } from '@angular/core';
import { ConvertNumberService } from '../services/convert-number.service'

@Pipe({
    name: 'convertPersianNo'
})

@Injectable()

export class ConvertPersianNo {

  constructor(public convertNumberService: ConvertNumberService) {}
    
  transform(value: string) {
    return this.convertNumberService.convertPersianNumbersToEnglish(value)
  }
}

