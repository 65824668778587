import {Component, Input, OnInit } from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements OnInit {

  @Input() control:FormControl = new FormControl('');
  @Input() type = 'text';
  @Input() label = '';
  @Input() format = '';
  @Input() fieldName = '';
  @Input() placeholder = '';
  @Input() isRequired = false;
  @Input() isPassword = false;
  @Input() isDrop = false;
  show = false;
  constructor() { }

  ngOnInit(): void {
    console.log(this.control)
  }

  onShowPassword() {
    this.show = !this.show;
    if (this.show) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

}
