import {Component, OnInit, OnChanges, SimpleChanges, Inject, AfterViewInit} from '@angular/core';
import * as L from 'leaflet';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.css']
})
export class MapModalComponent implements OnInit, OnChanges, AfterViewInit {

  latitude = 35.752466503801436;
  longitude = 51.412951755826;
  map: L.Map;
  marker: L.Marker;
  markerIcon = {
    icon: L.icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
      shadowUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png"
    })
  };
  options = {
    layers: [
      L.tileLayer(
        'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        { maxZoom: 18, attribution: '...' }
      )
    ],
    zoom: 17,
    scrollWheelZoom: true,
    doubleClickZoom: false,
    center: L.latLng(this.latitude, this.longitude),
  };

  constructor(public dialog: MatDialogRef<MapModalComponent>,@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    if (this.data?.clinic?.value?.latitude) {
      this.latitude = +this.data?.clinic?.value?.latitude;
    }
    if (this.data?.clinic?.value?.longitude) {
      this.longitude = +this.data?.clinic?.value?.longitude;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(this.latitude, this.longitude);
    if(changes.latitude.currentValue){
      this.createMarker(this.latitude, this.longitude)
    }
    if(changes.longitude.currentValue){
      this.createMarker(this.latitude, this.longitude)
    }
    this.createMarker(this.latitude, this.longitude);
  }

  ngAfterViewInit() {
    this.createMarker(this.latitude, this.longitude);
  }

  cancel() {
    this.dialog.close({type: 'cancel'});
  }

  createMarker(latitude: number, longitude: number) {
    if (this.marker) {
      this.map.removeLayer(this.marker);
    }
    this.marker = L.marker([latitude, longitude], this.markerIcon);
    this.marker.addTo(this.map);
    this.map.panTo(new L.LatLng(latitude, longitude));
  }

  onMapReady(map: L.Map) {
    this.map = map;
  }

  onMapDoubleClick(event: L.LeafletMouseEvent){
    if(this.marker) this.map.removeLayer(this.marker)
    this.marker = L.marker([event.latlng.lat, event.latlng.lng], this.markerIcon);
    this.marker.addTo(this.map);
    this.map.panTo(new L.LatLng(event.latlng.lat, event.latlng.lng));
    this.latitude = event.latlng.lat;
    this.longitude = event.latlng.lng;
  }

  getLocation(){
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position)=>{
        const longitude = position.coords.longitude;
        const latitude = position.coords.latitude;
        this.createMarker(latitude, longitude);
      });
    } else {
      console.log("No support for geolocation")
    }
  }

  onSaveLocation() {
    this.dialog.close({type: 'save' , latitude: this.latitude, longitude: this.longitude});
  }


}
