<div dir="rtl" >
    <ng-container *ngIf="label">
        <label>{{ label }}<span class="text-danger">*</span></label>
    </ng-container>
    <mat-select
            class="form-control"
            multiple
            (appScroll)="getMoreData()"
            [(ngModel)]="selectedItems"
            (selectionChange)="change($event)"
            [placeholder]="placeholder"
    >
        <mat-option disabled *ngIf="haveSearch">
            <input class="form-control" matInput type="text" placeholder="جستجو" (input)="onSearch($event)" >
        </mat-option>
        <mat-option *ngFor="let item of list" [value]="item.id">
            <div class="d-flex align-items-center">
                <div class="d-flex mx-3">
                    <span>{{ item?.name }}</span>
                </div>
            </div>
        </mat-option>
    </mat-select>
</div>
