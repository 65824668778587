<div class="rtl">
  <mat-form-field appearance="standard" style="width: 100%" *ngIf="selectedType === 'mat'">
    <ng-container *ngIf="label">
      <label class="control-label">{{ label }}</label>
    </ng-container>
    <mat-select
      (appScroll)="getMoreData()"
      [(ngModel)]="selectedItem"
      (selectionChange)="change($event)"
      [placeholder]="placeholder"
      #selectedItemInput="ngModel"
      name="selectedItem" required>
      <mat-option disabled>
        <input class="form-control" matInput type="text" placeholder="جستجو" (input)="onSearch($event)">
      </mat-option>
      <mat-option *ngFor="let item of selectList" [value]="item.id">
        <div class="d-flex align-items-center">
          <div class="d-flex mx-3">
            <span>{{ item?.cityTitleFA }}</span>
          </div>
        </div>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="selectedItemInput.invalid && (selectedItemInput.dirty || selectedItemInput.touched)">
      شهر الزامی است.
    </mat-error>
  </mat-form-field>


  <ng-container *ngIf="selectedType === 'profile'">
    <ng-container *ngIf="label">
      <label class="control-label">{{ label }}</label>
    </ng-container>
    <mat-select class="form-control" (appScroll)="getMoreData()" [(ngModel)]="selectedItem"
                (selectionChange)="change($event)" [placeholder]="placeholder" #selectedItemInput="ngModel"
                name="selectedItem" required>
      <mat-option disabled>
        <input class="form-control" matInput type="text" placeholder="جستجو" (input)="onSearch($event)">
      </mat-option>
      <mat-option *ngFor="let item of selectList" [value]="item.id">
        <div class="d-flex align-items-center">
          <div class="d-flex mx-3">
            <span>{{ item?.cityTitleFA }}</span>
          </div>
        </div>
      </mat-option>
    </mat-select>
    <mat-error *ngIf="selectedItemInput.invalid && (selectedItemInput.dirty || selectedItemInput.touched)">
      شهر الزامی است.
    </mat-error>
  </ng-container>


  <ng-container *ngIf="selectedType === 'normal'">
    <select class="custom-select" (change)="change($event)" [(ngModel)]="selectedItem" (appScroll)="getMoreData()"
            #selectedItemInput="ngModel" name="selectedItem" required>
      <option *ngFor="let item of selectList" [value]="item.id">{{ item?.cityTitleFA }}</option>
    </select>
    <div *ngIf="selectedItemInput.invalid && (selectedItemInput.dirty || selectedItemInput.touched)" class="invalid-feedback">
      شهر الزامی است.
    </div>
  </ng-container>
</div>
