import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";

interface ItemModel {
  id: number;
  name: string;
}

@Component({
  selector: 'app-select-with-chips',
  templateUrl: './select-with-chips.component.html',
  styleUrls: ['./select-with-chips.component.css']
})
export class SelectWithChipsComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label = '';
  @Input() items = [];
  @Input() selectedItems = [];

  constructor() { }

  ngOnInit(): void {}

  removeOption(option: string): void {
    const index = this.selectedItems.indexOf(option);
    if (index >= 0) {
      this.selectedItems.splice(index, 1);
      this.control.setValue(this.selectedItems.map(lang => lang.id));
    }
  }

  onChangeItems(event: any) {
    this.selectedItems = event.value.map((id: number) => {
      return this.items.find(lang => lang.id === id) as ItemModel;
    });
  }

}
