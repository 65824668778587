import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HttpService} from 'src/app/shared/services/http.service';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-upload-video',
  templateUrl: './upload-video.component.html',
  styleUrls: ['./upload-video.component.css']
})
export class UploadVideoComponent implements OnInit {

  selectedFile: File | null = null;
  load = false;
  @Input() service = 'doctor';
  @Input() fileName = '';
  @Output() loading = new EventEmitter<boolean>();
  @Output() error = new EventEmitter<string>();
  @Output() path = new EventEmitter<string>();
  constructor(private http: HttpService) { }

  ngOnInit(): void {
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  upload() {
    if (!this.selectedFile) {
      this.error.emit('لطفا فایل را انتخاب کنید');
      return;
    }
    this.load = true;
    this.loading.emit(true);
    this.http.postFile(this.selectedFile, this.service)
      .pipe(take(1))
      .subscribe(
        res => {
          this.loading.emit(false);
          this.load = false;
          this.path.emit(res.filename);
        },
        err => {
          this.loading.emit(false);
          this.load = false;
          this.error.emit('در آپلود تصویر مشکلی ایجاد شده است!');
        }
      );
  }

}
