import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-custom-single-select',
  templateUrl: './custom-single-select.component.html',
  styleUrls: ['./custom-single-select.component.css']
})
export class CustomSingleSelectComponent implements OnInit, OnChanges {

  @Input() label = '';
  @Input() selectList: any[] = [];
  @Input() placeholder = '';
  @Input() selectedItem: any;
  pageIndex = 0;
  @Output() moreData = new EventEmitter<number>();
  initialList = [];
  @Output() selectionChange = new EventEmitter<any[]>();
  @Input() totalItems: number = 0;
  @Input() selectedType = 'mat';

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectList) {
      this.initialList = this.selectList;
    }
  }

  getMoreData(): void {
    this.pageIndex += 1;
    if (this.selectList.length < this.totalItems) {
      this.moreData.emit(this.pageIndex);
    }
    this.cdr.detectChanges();
  }

  change(event: any) {
    if (this.selectedType === 'normal') {
      this.selectionChange.emit(event.target.value);
    } else {
      this.selectionChange.emit(event.value);
    }
  }

  onSearch(event: any): void {
    if (event.target.value.length > 0) {
      const list = [];
      for (const item of this.selectList) {
        const name = item?.cityTitleFA;
        if (name.includes((event.target.value))) {
          list.push(item);
        }
      }
      this.selectList = list;
    } else {
      this.selectList = this.initialList;
    }
  }

}
