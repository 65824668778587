<div class="map">
  <div style="height: 300px;"
       leaflet
       [leafletOptions]="options"
       (leafletMapReady)="onMapReady($event)"
       (leafletDoubleClick)="onMapDoubleClick($event)">
  </div>
  <a class="current-location" (click)="getLocation()">
    <i class="material-icons">my_location</i>
  </a>
</div>
