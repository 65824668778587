import { NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { ToastrModule } from "ngx-toastr";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import {SharedModule} from "./shared/shared.module";
import {GraphQLModule} from "./graphql.module";
import {PersianCalendarService} from "./shared/persian-date/persian-date.service";
import {AuthService} from "./shared/services/auth.service";
import {HandleResponseService} from "./shared/services/response.service";
import {JwtInterceptor} from "./shared/jwt.interceptor";
import {AuthInterceptorService} from "./shared/services/auth-interseptor.service";
import { AppComponent } from './app.component';
import {AuthModule} from "./auth/auth.module";
import { NgxMaskModule, IConfig } from "ngx-mask";

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
    SharedModule,
    GraphQLModule,
    AuthModule,

  ],
  providers: [
    PersianCalendarService,
    AuthService,
    // UserService,
    HandleResponseService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializer,
    //   multi: true,
    //   deps: [AuthService]
    // },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true,
    }
    // {
    //   provide: MatPaginatorIntl,
    //   useClass: CustomMatPaginatorIntl,
    // },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
