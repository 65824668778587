import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges
} from '@angular/core';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.css'],
})
export class FormSelectComponent implements OnInit, OnChanges {

  @Input() list: any[] = [];
  @Input() label = '';
  @Input() placeholder = '';
  @Input() haveSearch = true;
  @Input() totalItems: number = 0;
  @Input() selectedItems: any[] = [];

  @Output() moreData = new EventEmitter<number>();
  @Output() selectionChange = new EventEmitter<any[]>();

  pageIndex = 0;
  initialList = [];

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectList) {
      this.initialList = this.list;
    }
  }

  onSearch(event: any): void {
    if (event.target.value.length > 0) {
      const list = [];
      for (const item of this.list) {
        const name = item?.name;
        if (name.toLowerCase().includes((event.target.value).toLowerCase())) {
          list.push(item);
        }
      }
      this.list = list;
    } else {
      this.list = this.initialList;
    }
  }

  getMoreData(): void {
    this.pageIndex += 1;
    if (this.list.length < this.totalItems) {
      this.moreData.emit(this.pageIndex);
    }
    this.cdr.detectChanges();
  }
  change(event: any) {
    this.selectionChange.emit(event.value);
  }

}
