import {Component, Input, OnInit} from '@angular/core';
import {FormArray, FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-checkbox-mat',
  templateUrl: './checkbox-mat.component.html',
  styleUrls: ['./checkbox-mat.component.css']
})
export class CheckboxMatComponent implements OnInit {

  @Input() formGroup!: FormGroup;
  @Input() controlName!: string;
  @Input() label!: string;
  @Input() options!: { label: string, value: any }[];
  formArray!: FormArray;

  constructor() { }

  ngOnInit(): void {
    this.formArray = this.formGroup.get(this.controlName) as FormArray;
  }

  onCheckboxChange(event: any, value: any): void {
    if (event.checked) {
      this.formArray.push(new FormControl(value));
    } else {
      const index = this.formArray.controls.findIndex(x => x.value === value);
      this.formArray.removeAt(index);
    }
  }

  isChecked(value: any): boolean {
    return this.formArray.controls.some(control => control.value === value);
  }

}
