<div class="d-flex flex-column w-100 ">
  <div class="d-flex align-items-center justify-content-between ">
    <div class="d-flex align-items-center width-control">
      <input type="file" accept="video/*" (change)="onFileSelected($event)" style="position: absolute; display: none;" #fileInput />
      <button type="button" [disabled]="load" class="btn book-btn text-nowrap" (click)="fileInput.click()" >انتخاب ویدئو</button>
      <span class="mx-3 text-truncate uploader-text" *ngIf="selectedFile && selectedFile?.name" >{{ selectedFile?.name }}</span>
      <span class="mx-3 text-truncate uploader-text" *ngIf="!selectedFile && !selectedFile?.name && fileName" >{{ fileName }}</span>
    </div>

    <button type="button" [disabled]="load" class="btn btn-outline-success" (click)="upload()">ثبت</button>
  </div>
</div>
