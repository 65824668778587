<form [formGroup]="form">
  <div class="d-flex align-items-center ml-1">
    <input
      type="radio"
      [value]="value"
      [formControlName]="controlName"
      [id]="radioId"
      style="width: 1.15em; height: 1.15em;"
      class="mx-1 mb-2"
      (click)="onValueChange()" />
    <label class="" [for]="radioId">{{ label }}</label>
  </div>
</form>
