<mat-form-field class="w-100" appearance="standard" *ngIf="!haveSearch" >
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <mat-select [placeholder]="placeholder" [multiple]="isMultiple" [formControl]="control" (selectionChange)="onChangeSelect($event)">
        <ng-container *ngIf="selectList.length">
            <mat-option *ngFor="let item of selectList" [value]="item.id" >{{ item.name }}</mat-option>
        </ng-container>
    </mat-select>
    <mat-hint *ngIf="control.touched">
        <p class="form-errors" *ngIf="control?.errors?.['required']">
            {{ label }}
            الزامی است.
        </p>
        <p class="form-errors" *ngIf="control?.errors?.['minlength']">
            {{ label }}
            باید دارای حداقل
            {{ control.errors?.['minlength'].requiredLength  }}
            حرف باشد. شما
            {{ control.errors?.['minlength'].actualLength }}
            حرف وارد کرده اید.
        </p>
        <p class="form-errors" *ngIf="control?.errors?.['maxlength']">
            {{ label }}
            نباید بیشتر از
            {{ control.errors?.['maxlength'].requiredLength  }}
            حرف باشد. شما
            {{ control.errors?.['maxlength'].actualLength }}
            حرف وارد کرده اید.
        </p>
        <p class="form-errors" *ngIf="control?.errors?.['pattern']">
            {{ label }}
            وارد شده صحیح نمی باشد.
        </p>
        <p class="form-errors" *ngIf="control?.errors?.['min']">
            {{ label }}
            کمتر از حداقل مقدار است.
        </p>
        <p class="form-errors" *ngIf="control?.errors?.['max']">
            {{ label }}
            بیشتر از حداکثر مقدار است.
        </p>
        <p class="form-errors" *ngIf="control?.errors?.['email']">
            {{ label }}
            وارد شده صحیح نمی باشد.
        </p>
    </mat-hint>
</mat-form-field>

<mat-form-field class="w-100" appearance="standard" *ngIf="haveSearch" >
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <mat-select [placeholder]="placeholder" [multiple]="isMultiple"  [formControl]="control" (selectionChange)="onChangeSelect($event)" (appScroll)="getMoreData()" >
        <mat-option disabled>
            <input matInput type="text" placeholder="جستجو" (input)="onSearch($event)">
        </mat-option>
        <mat-option *ngFor="let item of selectList" [value]="item.id" >{{ item.name }}</mat-option>
    </mat-select>
    <mat-hint *ngIf="control.touched">
        <p class="form-errors" *ngIf="control?.errors?.['required']">
            {{ label }}
            الزامی است.
        </p>
        <p class="form-errors" *ngIf="control?.errors?.['minlength']">
            {{ label }}
            باید دارای حداقل
            {{ control.errors?.['minlength'].requiredLength  }}
            حرف باشد. شما
            {{ control.errors?.['minlength'].actualLength }}
            حرف وارد کرده اید.
        </p>
        <p class="form-errors" *ngIf="control?.errors?.['maxlength']">
            {{ label }}
            نباید بیشتر از
            {{ control.errors?.['maxlength'].requiredLength  }}
            حرف باشد. شما
            {{ control.errors?.['maxlength'].actualLength }}
            حرف وارد کرده اید.
        </p>
        <p class="form-errors" *ngIf="control?.errors?.['pattern']">
            {{ label }}
            وارد شده صحیح نمی باشد.
        </p>
        <p class="form-errors" *ngIf="control?.errors?.['min']">
            {{ label }}
            کمتر از حداقل مقدار است.
        </p>
        <p class="form-errors" *ngIf="control?.errors?.['max']">
            {{ label }}
            بیشتر از حداکثر مقدار است.
        </p>
        <p class="form-errors" *ngIf="control?.errors?.['email']">
            {{ label }}
            وارد شده صحیح نمی باشد.
        </p>
    </mat-hint>
</mat-form-field>
