<div class="d-flex flex-column w-100" mat-dialog-content>
  <h4 mat-dialog-title class="text-nowrap">{{ data.imgUrl ? 'ویرایش تصویر' : 'افزودن تصویر' }}</h4>
  <div *ngIf="error" class="alert alert-danger" role="alert">{{error}}</div>
  <mat-dialog-content>
    <div *ngIf="!imgChangeEvt" class="text-center">
      <img [src]="(data['imgUrl'])? fileURL+data['imgUrl'] : 'assets/img/noImage.png'">
    </div>
    <app-image-crop
            [resizeWidth]="300"
            [ratioOne]="data.ratioOne || 4"
            [ratioTwo]="data.ratioTwo || 4"
            [inputFile]="profileImageFile"
            [imageChangeEvent]="imgChangeEvt"
            (errorMessage)="changeErrorMessage($event)"
            (outputFile)="changeProfile($event)">
    </app-image-crop>
  </mat-dialog-content>
  <div mat-dialog-actions class="d-flex justify-content-between align-items-center pb-3" dir="rtl">
    <div class="upload-action d-flex flex-wrap mb-3">
      <div class="upload-img">
        <div class="change-photo-btn">
          <span class="fs-sm"><i class="fa fa-upload"></i> بارگذاری تصویر </span>
          <input type="file" class="upload" accept=".png, .jpg, .jpeg" (change)="onFileChange($event)">
        </div>
      </div>
      <small class="form-text text-muted px-1 text-nowrap">JPG ، GIF یا PNG مجاز است. حداکثر اندازه 2 مگابایت</small>
    </div>

    <div class="d-flex align-items-center">
      <button class="btn btn-primary m-1" type="submit" [disabled]="!profileImageFile || loading" (click)="save()" cdkFocusInitial>ثبت</button>
      <button class="btn btn-secondary m-1" type="reset" [disabled]="loading" (click)="cancel()">انصراف</button>
      <button class="btn btn-danger m-1" *ngIf="(data.imgUrl !== null || profileImageFile) && data.service === 'patient'" [disabled]="loading" (click)="onRemoveFile()">حذف</button>
    </div>
  </div>

</div>
