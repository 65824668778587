import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as L from 'leaflet';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, OnChanges {
  @Input() latitude = 35.752466503801436;
  @Input() longitude = 51.412951755826;
  @Output() changeCoordination = new EventEmitter<any>();
  map: L.Map;
  marker: L.Marker;
  markerIcon = {
    icon: L.icon({
      iconSize: [25, 41],
      iconAnchor: [10, 41],
      popupAnchor: [2, -40],
      iconUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-icon.png",
      shadowUrl: "https://unpkg.com/leaflet@1.4.0/dist/images/marker-shadow.png"
    })
  };
  options = {
    layers: [
      L.tileLayer(
        'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        { maxZoom: 18, attribution: '...' }
      )
    ],
    zoom: 17,
    scrollWheelZoom: false,
    doubleClickZoom: false,
    center: L.latLng(this.latitude, this.longitude),
  };

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.latitude.currentValue){
      this.onMapCoordChanged(this.latitude, this.longitude)
    }
    if(changes.longitude.currentValue){
      this.onMapCoordChanged(this.latitude, this.longitude)
    }
  }

  onMapCoordChanged(lat: any, lng: any){
    if(this.marker) this.map.removeLayer(this.marker)
    this.marker = L.marker([lat, lng], this.markerIcon);
    this.marker.addTo(this.map);
    this.map.panTo(new L.LatLng(lat, lng));
  }

  onMapReady(map: L.Map) {
    this.map = map;
  }

  onMapDoubleClick(event: L.LeafletMouseEvent){
    if(this.marker) this.map.removeLayer(this.marker)
    this.marker = L.marker([event.latlng.lat, event.latlng.lng], this.markerIcon);
    this.marker.addTo(this.map);
    this.map.panTo(new L.LatLng(event.latlng.lat, event.latlng.lng));
    this.changeCoordination.emit([event.latlng.lat, event.latlng.lng])
  }

  getLocation(){
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position)=>{
        const longitude = position.coords.longitude;
        const latitude = position.coords.latitude;

        if(this.marker) this.map.removeLayer(this.marker)
        this.marker = L.marker([longitude, latitude], this.markerIcon);
        this.marker.addTo(this.map);
      });
    } else {
      console.log("No support for geolocation")
    }
  }
}
