<div class="row">
  <div class="col-7">
    <image-cropper
      [containWithinAspectRatio]="true"
      [imageChangedEvent]="imageChangeEvent"
      [maintainAspectRatio]="false"
      [aspectRatio]="(ratioOne !== 1 && ratioTwo !== 1) ? (ratioOne / ratioTwo) : 1"
      [resizeToWidth]="resizeWidth"
      format="png"
      (imageCropped)="cropImg($event)"
      (loadImageFailed)="imgFailed()"
      class="p-0"
      style="max-height: 300px">
    </image-cropper>
  </div>
  <div class="col-1"></div>
  <div class="col-4" style="max-height: 300px;" *ngIf="imageChangeEvent">
    <img [src]="cropImgPreview" width="auto" class="rounded" style="max-width: 100%; max-height: 300px"/>
  </div>
</div>
