import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import { Router} from "@angular/router";
import {AuthService} from "src/app/shared/services/auth.service";
import {User} from "src/app/shared/models/user.model";
import {SubSink} from "subsink";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy {

  mobileQuery: MediaQueryList;

  events: string[] = [];
  // userSub: Subscription;
  userInfo: User;
  role = 'SuperAdmin';
  private subs = new SubSink();
  screenSize = 0;
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.screenSize = window.innerWidth;
  }
  private _mobileQueryListener: () => void;
  constructor(private auth: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    this.screenSize = window.innerWidth;
    this.subs.add(this.auth.user.subscribe(res => {
        if (res) {
          this.getUserInfo();
          if (res.role.includes('SuperAdmin')) {
            this.role = 'SuperAdmin';
          } else {
            this.role = res.role[0];
          }
        }
    }));
  }

  getUserInfo() {
    this.subs.add(this.auth.getUserInfo().subscribe(
        res => {
          this.userInfo = res;
        },
        err => console.log(err)
      ));
  }

  signOut(){
    this.auth.signOut();
  }

  isDrawerExpanded(menuItem: string): boolean {
    return this.router.url.split('/')[1] === menuItem;
  }

  ngOnDestroy(){
    // this.userSub.unsubscribe();
    // this.mobileQuery.removeListener(this._mobileQueryListener);
    this.subs.unsubscribe();
  }

}
