import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.css']
})
export class FormCheckboxComponent implements OnInit {

  @Input() title = '';
  @Input() control: any = new FormControl(false);
  @Input() id = 'checkbox';
  constructor() { }

  ngOnInit(): void {
  }

}
