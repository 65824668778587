import { Injectable } from '@angular/core';

@Injectable()

export class ConvertNumberService {

  convertPersianNumbersToEnglish(string) {
    if (!string) return string;
    return string.replace(/[\u0660-\u0669]/g, function (c) {
      return c.charCodeAt(0) - 0x0660;
    }).replace(/[\u06f0-\u06f9]/g, function (c) {
      return c.charCodeAt(0) - 0x06f0;
    })
  }

}