import { Injectable, Pipe } from '@angular/core';
import { PersianCalendarService } from './persian-date.service';

@Pipe({
    name: 'persianShortDate'
})

@Injectable()

export class PersianShortDate {

    constructor(public persianCalendarService: PersianCalendarService) {
    }

    transform(value: string) {
        let d = new Date(value);
        return this.persianCalendarService.PersianShortCalendar(d);
    }
}