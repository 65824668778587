<div class="w-100">
    <mat-form-field appearance="outline" class="full-width">
        <mat-label>{{ label }}</mat-label>
        <div class="checkbox-group">
            <mat-checkbox
                    *ngFor="let option of options"
                    [checked]="isChecked(option.value)"
                    (change)="onCheckboxChange($event, option.value)">
                {{ option.label }}
            </mat-checkbox>
        </div>
    </mat-form-field>
</div>
