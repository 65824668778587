import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-input-mat',
  templateUrl: './input-mat.component.html',
  styleUrls: ['./input-mat.component.css']
})
export class InputMatComponent implements OnInit {
  @Input() control:FormControl = new FormControl('');
  // @Input() form: FormGroup;
  @Input() type = 'text';
  @Input() format = '';
  @Input() label = '';
  @Input() placeholder = '';
  @Input() isPassword = false;
  @Input() isDrop = false;
  show = false;
  constructor() {}

  ngOnInit(): void {}

  onShowPassword() {
    this.show = !this.show;
    if (this.show) {
      this.type = 'text';
    } else {
      this.type = 'password';
    }
  }

}
