<div class="w-100" [formGroup]="form">
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{ label }}</mat-label>
        <input
            matInput
            [type]="type"
            [mask]="mask"
            [dropSpecialCharacters]="isDrop"
            [placeholder]="placeholder"
            [formControlName]="controlName" >
        <mat-hint *ngIf="control.get(controlName).touched">
            <p class="form-errors" *ngIf="control.get(controlName).hasError('required')">
                {{ label }}
                الزامی است.
            </p>
            <p class="form-errors" *ngIf="control.get('controlName').hasError('pattern')">
                {{ label }}
                وارد شده صحیح نمی باشد.
            </p>
            <p class="form-errors" *ngIf="control.get('controlName').hasError('min')">
                {{ label }}
                کمتر از حداقل مقدار است.
            </p>
            <p class="form-errors" *ngIf="control.get('controlName').hasError('max')">
                {{ label }}
                بیشتر از حداکثر مقدار است.
            </p>
            <p class="form-errors" *ngIf="control.get('controlName').hasError('email')">
                {{ label }}
                وارد شده صحیح نمی باشد.
            </p>
            <p class="form-errors" *ngIf="control.get('controlName').hasError('minlength')">
                {{ label }}
                باید دارای حداقل
                {{ control.get('controlName').errors?.['minlength'].requiredLength  }}
                حرف باشد. شما
                {{ control.get('controlName').errors?.['minlength'].actualLength }}
                حرف وارد کرده اید.
            </p>
            <p class="form-errors" *ngIf="control.get('controlName').hasError('maxlength')">
                {{ label }}
                نباید بیشتر از
                {{ control.get('controlName').errors?.['maxlength'].requiredLength  }}
                حرف باشد. شما
                {{ control.get('controlName').errors?.['maxlength'].actualLength }}
                حرف وارد کرده اید.
            </p>
            <p class="form-errors" *ngIf="form?.errors?.['mustMatch']">
                {{ label }}
                وارد شده مطابقت ندارد.
            </p>
        </mat-hint>
    </mat-form-field>
</div>
