<aside>
  <mat-toolbar class="bg-light border-bottom">
    <button mat-icon-button (click)="drawer.toggle()">
      <mat-icon class="pt-2">menu</mat-icon>
    </button>
    <a routerLink="/dashboard" class="navbar-brand logo">
      <img src="assets/img/logo.png" class="img-fluid main-logo" alt="Logo">
    </a>

    <span class="toolbar-spacer"></span>

    <div class="d-flex align-items-center px-2">
      <a title="ارسال تیکت" routerLink="/dashboard/tickets">
        <img src="assets/img/icons/message.svg" alt="messages">
      </a>
      <a [matMenuTriggerFor]="menu" title="اعلانات" class="mx-3">
        <span class="new-notification"></span>
        <img src="assets/img/icons/bell.svg" alt="notifications">
      </a>
      <a (click)="signOut()" title="خروج">
        <img src="assets/img/icons/power.svg" alt="logout">
      </a>
    </div>

    <mat-menu #menu="matMenu" yPosition="below" xPosition="before">
      <ul class="d-flex flex-column w-100 m-0 p-0" style="list-style: none; direction: rtl">
        <li class="p-3 hover-notification" [routerLink]="['/dashboard/notification', 0]">
          <a   >شما یک پیام جدید دارید.</a>
        </li>
        <li class="p-3 hover-notification" [routerLink]="['/dashboard/notification', 1]">
          <a >یک تیکت جدید دارید.</a>
        </li>
      </ul>
    </mat-menu>
  </mat-toolbar>

  <mat-drawer-container class="example-container" dir="ltr">
    <mat-drawer #drawer [mode]="screenSize >= 1200 ? 'side' : 'over'" [opened]="screenSize >= 1200" position="end">
      <mat-accordion class="example-headers-align" dir="rtl" multi>

        <div class="d-flex align-items-center justify-content-center mt-3">
          <div class="d-flex flex-column align-items-center">
            <div style="background-color: #09e5ab; padding: 2px; border-radius: 100%">
              <img src="assets/img/noImage.png" alt="avatar" style="width: 48px;height: 48px;border-radius: 100%">
            </div>
            <span class="mt-2" style="color: #31363F">{{userInfo?.name}}&nbsp;{{userInfo?.last_name}}</span>
          </div>
        </div>

        <hr class="w-100">

        <mat-expansion-panel class="mat-elevation-z" [expanded]="isDrawerExpanded('dashboard')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>dashboard</mat-icon>
              <span>داشبورد</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="pr-4">
            <div class="p-2">
              <a routerLink="/dashboard" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">خانه</a>
            </div>
            <div class="p-2">
              <a routerLink="/dashboard/notifications" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">اعلانات</a>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel
            class="mat-elevation-z"
            [expanded]="isDrawerExpanded('doctors')"
            *ngIf="role === 'Admin' || role === 'ConsultantSupportAdmin' || role === 'SuperAdmin' " >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>assignment_ind</mat-icon>
              <span>مدیریت پزشکان</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="pr-4">
            <div class="p-2">
              <a
                routerLink="/doctors"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">لیست پزشکان</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/doctors/doctor-management"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">پزشک جدید</a>
            </div>
<!--            <div class="p-2">-->
<!--              <a routerLink="/doctors/register" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">سهم پزشکان</a>-->
<!--            </div>-->
            <div class="p-2">
              <a
                routerLink="/doctors/comments"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">نظرات کاربران</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/doctors/settings"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">تنظیمات و زمان بندی</a>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel
            class="mat-elevation-z"
            [expanded]="isDrawerExpanded('finance')"
            *ngIf="role === 'Admin' || role === 'SuperAdmin' || role === 'FinanceAdmin' " >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>monetization_on</mat-icon>
              <span>امور مالی و قرارداد</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="pr-4">
            <div class="p-2">
              <a
                routerLink="/finance"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">گزارشات مالی سازمان</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/finance/doctor-finance-management"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">مدیریت مالی پزشکان</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/finance/consultant-finance-management"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">گزارشات مالی مشاوره ها</a>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel
            class="mat-elevation-z"
            [expanded]="isDrawerExpanded('users')"
            *ngIf="role === 'Admin' || role === 'SuperAdmin' || role === 'PatientSupportAdmin' " >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>date_range</mat-icon>
              <span>مدیریت کاربران</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="pr-4">
            <div class="p-2">
              <a
                routerLink="/users"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">لیست کاربران</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/users/user-register"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">ثبت نام جدید</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/users/user-sponsor-accounts"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">افراد تحت تکفل</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/users/user-feedbacks"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">نظرات و پیشنهادات</a>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel
            class="mat-elevation-z"
            [expanded]="isDrawerExpanded('consultant')"
            *ngIf="role === 'Admin' || role === 'SuperAdmin' || role === 'ConsultantSupportAdmin' " >
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>business</mat-icon>
              <span>مدیریت مشاوره ها</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="pr-4">
            <div class="p-2">
              <a
                routerLink="/consultant"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">لیست مشاوره ها</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/consultant/consultant-requests"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">درخواست ها</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/consultant/nonpayment-consultants"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">وضعیت مالی مشاوره ها</a>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel
            class="mat-elevation-z"
            [expanded]="isDrawerExpanded('blogs')"
            *ngIf="role === 'Admin' || role === 'SuperAdmin' || role === 'ConsultantSupportAdmin' ">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <mat-icon>assignment</mat-icon>
              <span>مدیریت مقالات</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="pr-4">
            <div class="p-2">
              <a
                routerLink="/blogs"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">لیست مقالات</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/blogs/new-blog"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">مقاله جدید</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/blogs/blog-groups"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">گروه مقالات</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/blogs/blog-comments"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">نظرات مقالات</a>
            </div>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel
            class="mat-elevation-z"
            [expanded]="isDrawerExpanded('settings')"
            *ngIf="role === 'Admin' || role === 'SuperAdmin'">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <i class="fa fa-cog" aria-hidden="true"></i>
              <span>تنظیمات</span>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="pr-4">
            <div class="p-2">
              <a
                routerLink="/settings"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">تنظیمات عمومی</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/settings/cancellation"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">قوانین کنسلی</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/settings/roles-management"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">مدیریت نقش ها</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/settings/specialties-management"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">تنظیمات لیست تخصص ها</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/settings/tags-management"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">مدیریت تگ ها</a>
            </div>
            <div class="p-2">
              <a
                routerLink="/settings/notifications-management"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}">تنظیمات اعلانات</a>
            </div>
          </div>
        </mat-expansion-panel>

        <div class="pr-4 pt-2 d-flex align-items-center text-danger" (click)="signOut()">
          <i class="fa fa-power-off" style="font-size: 18px; cursor: pointer" aria-hidden="true"></i>
          <div class="p-2">
            <a class="text-danger pr-2" style="font-size: 16px">خروج</a>
          </div>
        </div>

      </mat-accordion>
    </mat-drawer>
    <mat-drawer-content dir="rtl">
      <main class="w-100 h-100">
        <router-outlet></router-outlet>
      </main>
    </mat-drawer-content>
  </mat-drawer-container>

</aside>
