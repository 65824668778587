import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";
import {SelectValueModel} from "../../../models/select.value.model";

@Component({
  selector: 'app-normal-select',
  templateUrl: './normal-select.component.html',
  styleUrls: ['./normal-select.component.css']
})
export class NormalSelectComponent implements OnInit {

  @Input() id = '';
  @Input() label = '';
  @Input() control = new FormControl('');
  @Input() list: SelectValueModel[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
