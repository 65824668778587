<div class="map">
  <div style="height: 500px;"
       leaflet
       [leafletOptions]="options"
       (leafletMapReady)="onMapReady($event)"
       (leafletDoubleClick)="onMapDoubleClick($event)">
  </div>
  <a class="current-location" (click)="getLocation()">
    <i class="material-icons">my_location</i>
  </a>
</div>
<div class="d-flex align-items-center my-3 justify-content-start">
  <button class="btn btn-primary" type="button" (click)="onSaveLocation()">ثبت</button>
  <button class="btn btn-secondary mx-3" type="button" (click)="cancel()">انصراف</button>
</div>
