import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { ToasterService } from './toaster.service';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class HandleResponseService {

    constructor(private toaster: ToasterService){}

    public handleSuccess(req: HttpRequest<any>, res: HttpResponse<any>){
        // if(req.method === 'POST'){
        //     this.toaster.showSuccess('Data Inserted Successfully','Success')
        // }
    }

    public handleError(err: HttpErrorResponse){
        //let errorMessage: string;
        
        // if (err.error instanceof ErrorEvent){
        //     errorMessage = `An error occurred: ${err.error.message}`;
        // }
        // else {
        //     switch(err.status){
        //         case 400:
        //             errorMessage = 'خطای 400 - نوع درخواست ارسال شده اشتباه است!'
        //             break;
        //         case 401:
        //             errorMessage = 'خطای 401 - ابتدا وارد سایت شوید!'
        //             break;
        //         case 403:
        //             errorMessage = 'خطای 403 - شما اجازه دسترسی به این سرویس را ندارید!'
        //             break;
        //         case 404:
        //             errorMessage = '404'
        //             break;
        //         case 409:
        //             errorMessage = 'Error 409 - Conflict'
        //             break;
        //         case 500:
        //             errorMessage = 'خطای 500 - خطای سیستمی رخ داده است!'
        //             break;
        //         default:
        //             errorMessage = 'خطایی رخ داده است!';
        //     }
        //}

        //this.toaster.showError(errorMessage, 'Error')
        //return throwError(errorMessage)

        return throwError(err)

    }

    public showError(error){
        
    }
}