import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.css']
})
export class ListSearchComponent implements OnInit {

  @Input() service: string
  @Input() controller: string
  @Input() action: string
  @Input() title: string = 'جستجو'
  @Output() valueChange = new EventEmitter<any>()
  @ViewChild('searchInput') input: ElementRef

  value: any
  loading: boolean
  items: Array<any> = []
  params = {PageIndex: 0, PageSize: 10}
  timeout: any

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this.loading = true
    this.http.get(`${environment[this.service]}/${this.controller}/${this.action}`, {params: this.params})
      .subscribe(
        res => {
          this.items = res['resultItems']
          this.loading = false
        },
        err => {
          this.loading = false
        }
      )
  }

  displayFn(item: any): string {
    return item && item.firstName && item.lastName? (item.firstName + ' ' + item.lastName) : '';
  }

  onKeySearch(event: any) {
    if (this.controller != 'Doctor') return

    clearTimeout(this.timeout)
    var $this = this
    this.timeout = setTimeout(function () {
      if (event.keyCode != 13) {
        if (!event.target.value) $this.clear()
        if (!event.target.value && $this.params['Keyword']) delete $this.params['Keyword']
        else if (event.target.value) $this.params['Keyword'] = event.target.value
        $this.getList()
      }
    }, 1000)
  }

  onSelectionChanged(event: any) {
    if (this.controller == 'Doctor') this.valueChange.emit(event.option.value.globalUserId)
    if (this.controller == 'Patient') this.valueChange.emit(event.option.value.userGlobalId)
  }

  clear() {
    this.value = null
    this.valueChange.emit(null)
  }

}
