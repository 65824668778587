import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {SelectModel} from "../../../models/select.model";
import {FormControl} from "@angular/forms";


@Component({
  selector: 'app-select-mat',
  templateUrl: './select-mat.component.html',
  styleUrls: ['./select-mat.component.css']
})
export class SelectMatComponent implements OnInit, OnChanges {

  @Input() placeholder = '';
  @Input() label = '';
  @Input() selectList: SelectModel[] = [];
  @Input() control = new FormControl<string | number | null>('');

  @Input() totalItems: number = 0;
  @Input() haveSearch: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isMultiple: boolean = false;
  @Output() selectChange = new EventEmitter();
  @Output() moreData = new EventEmitter<number>();
  pageIndex = 0;
  initialList = [];

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.selectList) {
      this.initialList = this.selectList;
    }
  }

  onChangeSelect(event: any) {
    this.selectChange.emit(event);
  }

  getMoreData(): void {
    this.pageIndex += 1;
    if (this.selectList.length < this.totalItems) {
      this.moreData.emit(this.pageIndex);
    }
    this.cdr.detectChanges();
  }

  onSearch(event: any): void {
    if (event.target.value.length > 0) {
      const list = [];
      for (const item of this.selectList) {
        const name = item.name;
        if (name.includes((event.target.value))) {
          list.push(item);
        }
      }
      this.selectList = list;
    } else {
      this.selectList = this.initialList;
    }
  }

}
