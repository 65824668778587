<div class="text-center d-flex flex-column align-items-center justify-content-center w-100 h-100">
  <h3 class="d-flex align-items-center">
    <i class="fa fa-meh-o" aria-hidden="true" style="font-size: 28px;"></i>
    <span class="mx-1">
    متاسفانه صفحه موردنظر شما یافت نشد!
    </span>
  </h3>
  <p>برای پیدا کردن مسیر درست به
    <a (click)="onMainPageLinkClicked()" class="text-info">صفحه اصلی</a>
    سایت برگردید.
  </p>
</div>
