import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './login/login.component';
import {SharedModule} from "../shared/shared.module";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {AcceptRulesComponent} from "./accept-rules/accept-rules.component";


@NgModule({
  declarations: [
    LoginComponent,
    AcceptRulesComponent,
  ],
  exports: [
    LoginComponent
  ],
  imports: [
    AuthRoutingModule,
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ]
})
export class AuthModule { }
