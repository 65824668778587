import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-radio-mat',
  templateUrl: './radio-mat.component.html',
  styleUrls: ['./radio-mat.component.css']
})
export class RadioMatComponent implements OnInit {

  @Input() radioFormGroup!: FormGroup;
  @Input() controlName: string = '';
  @Input() label: string = '';
  @Input() options!: { label: string, value: any }[];

  control!: FormControl;
  constructor() {}

  ngOnInit(): void {
    this.control = this.radioFormGroup.get(this.controlName) as FormControl;
  }

}
