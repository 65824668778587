import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ImageCroppedEvent, base64ToFile  } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-crop',
  templateUrl: './image-crop.component.html',
  styleUrls: ['./image-crop.component.css']
})
export class ImageCropComponent implements OnInit {

  // @Input() control = new FormControl();
  @Input() ratioOne = 1;
  @Input() ratioTwo = 1;
  @Input() resizeWidth = 1;
  @Input() inputFile: File;
  @Input() imageChangeEvent: any;
  @Output() errorMessage = new EventEmitter<string>();
  @Output() outputFile = new EventEmitter<File>();

  error = '';
  // imgChangeEvt: any = '';
  cropImgPreview: any = '';
  // profileImageFile: File;
  constructor() { }

  ngOnInit(): void {
  }

  cropImg(e: ImageCroppedEvent) {
    this.cropImgPreview = e.base64;
    this.inputFile = this.base64ToFile(
      e.base64,
      this.imageChangeEvent.target.files[0].name,
    );
    //this.profileImageFile = this.imgChangeEvt.target.files[0];
    this.outputFile.emit(this.inputFile);
    // this.control.setValue(this.inputFile['name']);
  }

  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  imgFailed() {
    this.error = "مشکلی در بارگزاری تصویر رخ داده است!";
    this.errorMessage.emit(this.error);
    setTimeout(function () { this.errorMessage.email('') }.bind(this), 5000);
  }

}
