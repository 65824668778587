<div class="w-100">
    <label *ngIf="label" class="inline-block mb-2">{{ label }} <span class="text-danger" *ngIf="isRequired">*</span></label>
    <input
        [type]="type"
        [formControl]="control"
        class="form-control"
        [mask]="format"
        [dropSpecialCharacters]="isDrop"
        [placeholder]="placeholder"
        [ngClass]="{ 'is-invalid': control.touched && control.errors }"/>
    <div class="input-group-append" *ngIf="isPassword">
        <a class="input-group-text" id="inputGroupAppend" (click)="onShowPassword()">
            <i class="fa" [ngClass]="show ? 'fa-eye-slash' : 'fa-eye'"></i>
        </a>
    </div>
    <ng-container *ngIf="control.touched">
        <p class="invalid-feedback" *ngIf="control?.errors?.['required']">
            {{ fieldName }}
            الزامی است.
        </p>
        <p class="invalid-feedback" *ngIf="control?.errors?.['minlength']">
            {{ fieldName }}
            باید دارای حداقل
            {{ control.errors?.['minlength'].requiredLength  }}
            حرف باشد. شما
            {{ control.errors?.['minlength'].actualLength }}
            حرف وارد کرده اید.
        </p>
        <p class="invalid-feedback" *ngIf="control?.errors?.['maxlength']">
            {{ fieldName }}
            نباید بیشتر از
            {{ control.errors?.['maxlength'].requiredLength  }}
            حرف باشد. شما
            {{ control.errors?.['maxlength'].actualLength }}
            حرف وارد کرده اید.
        </p>
        <p class="invalid-feedback" *ngIf="control?.errors?.['pattern']">
            {{ fieldName }}
            وارد شده صحیح نمی باشد.
        </p>
        <p *ngIf="control?.errors?.['pattern'] && type === 'password'">
            {{ fieldName }}
            باید شامل حروف کوچک و بزرگ و عدد باشد.
        </p>
        <p class="invalid-feedback" *ngIf="control?.errors?.['min']">
            {{ fieldName }}
            کمتر از حداقل مقدار است.
        </p>
        <p class="invalid-feedback" *ngIf="control?.errors?.['max']">
            {{ fieldName }}
            بیشتر از حداکثر مقدار است.
        </p>
        <p class="invalid-feedback" *ngIf="control?.errors?.['email']">
            {{ fieldName }}
            وارد شده صحیح نمی باشد.
        </p>
        <p class="invalid-feedback" *ngIf="control?.errors?.['noMatch']">
            {{ label }}
            وارد شده مطابقت ندارد.
        </p>
    </ng-container>
</div>
