<div class="card">
  <div class="card-body">
    <h4 class="card-title">قوانین و مقررات</h4>
    <div class="mt-4">
      <ul>
        <li>
          <h4>ماده 1. واژگان</h4>
          <div class="mb-2">معانی واژه‌های به‌کاررفته در متن زیر به این شرح است:</div>
          <ul>
            <li>
              <span>1.1. شرکت کمک رسان ایران</span>
              شرکت کمک‌رسان ایران به شمارۀ ثبت .... که خدمات کلینیک آنلاین ISOS را ارائه می‌دهد.

            </li>
            <li>
              <span>1.2. کلینیک آنلاین ISOS</span>
              بستر نرم‌افزاری شرکت کمک‌رسان ایران برای ارائۀ خدمات تخصصی پزشکی و پیراپزشکی از سوی خدمت‌دهنده به متقاضی
              خدمت.

            </li>
            <li>
              <span>1.3. قرارداد خصوصی</span>
              توافق کتبی یا الکترونیکی بین شرکت کمک‌رسان ایران و خدمت‌دهنده، برای بهره‌برداری موقت از پایگاه ISOS.
            </li>
            <li>
              <span>1.4. کاربر</span>
              شخصی حقیقی یا حقوقی است، اعم از خدمت گیرنده و خدمت دهنده، که بر اساس شرایط و قوانین حاضر از طریق اینترنت
              به اپلیکیشن متصل و از خدمات ISOS بهره‌مند می‌شود.

            </li>
            <li>
              <span>1.5. کاربران</span>
              از کاربران خدمت دهنده و گیرنده، تحت عنوان کاربران یاد می‌شود.
            </li>
            <li>
              <span>1.6. کاربر خدمت دهنده</span>
              شخص حقیقی است که در اپلیکیشن خدمت دهنده ISOS برای دریافت درخواست خدمت و ارائۀ خدمات ثبت‌‌نام کرده ‌است و
              بر اساس قوانین کشور ایران مجوز یا پروانه مورد نیاز برای ارائه خدمت مورد نظر در حوزه تخصص ثبت شده در
              سامانه را دارد و ممنوعیت یا منعی از طرف مراجع ذیصلاح برای فعالیت ندارد.

            </li>
            <li>
              <span>1.7. کاربر خدمت گیرنده</span>
              شخص حقیقی یا حقوقی است که در اپلیکیشن خدمت گیرنده ISOS برای ارسال درخواست خدمت ثبت‌‌نام کرده‌ است.
            </li>
            <li>
              <span>1.8. حساب کاربری</span>
              حسابی است که اشخاص برای استفاده از خدمات ISOS در اپلیکیشن ساخته‌اند.
            </li>
            <li>
              <span>1.9. اعتبار</span>
              مبلغی است که کاربران در حساب کاربری خود به منظور استفاده از خدمات ISOS دارند. این مبلغ به صورت پرداخت از
              طریق سامانۀ بانکی و کارت‌های عضو شبکۀ شتاب و یا با استفاده از کیف پول طبق شرایط و قوانین حاضر، منظور و
              محاسبه خواهد شد.

            </li>
            <li>
              <span>1.10. اطلاعات محرمانه</span>
              اطلاعاتی که توسط کاربران در ISOS قرار داده می‌شود، اعم از اطلاعاتی که به صورت مستقیم از سوی کاربران وارد
              می‌شود و همچنین اطلاعاتی که به صورت خودکار و در نتیجۀ بهره‌برداری از خدمات ISOS توسط شرکت تولید یا در
              اختیار شرکت قرار می‌گیرد.

            </li>
            <li>
              <span>1.11. قوانین و مقررات جمهوری اسلامی</span>
              قانون اساسی، قوانین عادی، آئین‌نامه‌های اجرایی، بخشنامه‌ها، دستورالعمل‌ها و کلیه ضوابط قانونی حاکم بر
              انجام موضوع قرارداد، اعم از قوانین حوزه بهداشت و درمان و علوم پزشکی.

            </li>
            <li>
              <span>1.12. مشاوره یا توصیه</span>
              هرگونه گفتار، نوشتار یا عملی که در پاسخ به درخواست کاربر خدمت گیرنده از سوی کاربر متخصص (از طریق بستر
              سامانه ISOS) به وی ارائه می شود.

            </li>
          </ul>
        </li>
        <li>
          <h4>ماده 2. حساب کاربری</h4>
          <ul>
            <li>
              <span>2.1.</span>
              کاربران با ثبت‌نام در اپلیکیشن ISOS، همچنین با هر بار استفاده از خدمات آن، می‌پذیرند که شرایط و قوانین
              حاضر را به صورت کامل مطالعه کرده و آن را با اطلاع کامل از شرایط و معانی‌اش پذیرفته‌اند. این شرایط و
              قوانین ممکن است در طول زمان تغییر کند. استفادۀ کاربران از اپلیکیشن، پس از هر بار تغییر در شرایط و
              قوانین، به معنی پذیرش تغییرات مذکور است. این قوانین برای مطالعه کاربران در سایت و اپلیکیشن ISOS در دسترس
              است.

            </li>
            <li>
              <span>2.2.</span>
              برای استفاده از خدمات ISOS لازم است که هر کاربر اعم از شخص حقیقی یا حقوقی یک حساب کاربری در اپلیکیشن
              بسازد. تنها اشخاصی که بیش از ۱۸ سال سن دارند و واجد اهلیت قانونی هستند، می‌توانند اقدام به ساخت حساب
              کاربری در اپلیکیشن کنند. کاربران برای استفاده از خدمات باید نام، نام خانوادگی، آدرس پست الکترونیکی و
              شماره همراه خود را در اپلیکیشن ISOS ثبت کند.

            </li>
            <li>
              <span>2.3.</span>
              کاربران شخص حقوقی مکلف به معرفی نماینده (شخص حقیقی) برای ثبت‌نام در اپلیکیشن هستند و اطلاعات این شخص
              حقوقی باید در اپلیکیشن قید شود.

            </li>
            <li>
              <span>2.4.</span>
              هیچ‌یک از کاربران حق استفاده از ربات و نرم‌افزارهای مرتبط یا واسط را برای استفاده از اپلیکیشن اعم از
              احراز هویت، تکمیل اطلاعات، ارسال درخواست و غیره ندارند.

            </li>
            <li>
              <span>2.5.</span>
              کاربران می‌پذیرند کلیه اطلاعات درخواستی اپلیکیشن را مطابق با واقعیت، صحیح و به‌‌روز وارد کنند و شخص
              کاربر مسئول عدم صحت و به‌ روز نبودن اطلاعات است. در صورت اثبات خلاف این موضوع، هیچ مسئولیتی در زمینه
              خسارات و مشکلات ناشی از عدم اعتبار اطلاعات اعلام شده، بر عهده شرکت نخواهد بود.

            </li>
            <li>
              <span>2.6.</span>
              کاربران متعهد می‌شوند که صرفا با ایمیل متعلق به خود در اپلیکیشن ثبت‌نام کنند. در غیر این صورت در برابر
              شخص ذی‌نفع صاحب ایمیل تماما مسئول بوده و شرکت به محض آگاهی از این موضوع، مجاز است نسبت به لغو عضویت و
              حساب کاربری اقدام کند.

            </li>
            <li>
              <span>2.7.</span>
              هر شخص می‌تواند فقط یک حساب کاربری به عنوان کاربر خدمت دهنده یا کاربر خدمت گیرنده داشته باشد. در
              صورتی‌که شرکت اطلاع یابد که حساب‌های کاربری متعددی به نام یک شخص اعم از حقیقی و یا حقوقی وجود دارد، مجاز
              است نسبت به قطع خدمات و غیرفعال کردن حساب‌های کاربری مذکور اقدام کند.

            </li>
            <li>
              <span>2.8.</span>
              مسئولیت همۀ فعالیت‌هایی که از طریق حساب کاربری اشخاص حقیقی و یا حقوقی در اپلیکیشن انجام می‌شود، به عهدۀ
              کاربران است. بنابراین مسئولیت اشخاص ثالث یا افرادی غیر از کاربر که با آن حساب از خدمات اپلیکیشن استفاده
              می‌کنند، تماما متوجه کاربر صاحب‌حساب است.

            </li>
            <li>
              <span>2.9.</span>
              مسئولیت حفظ امنیت اطلاعات حساب کاربری شامل نام کاربری و رمز عبور به عهدۀ کاربران است. در صورت مفقود شدن
              یا به سرقت رفتن اطلاعات حساب کاربری و گوشی موبایل، کاربران موظف هستند در اسرع وقت موضوع را به اطلاع شرکت
              برساند. بدیهی است تا زمانی که اطلاع‌رسانی به شرکت انجام نشده، مسئولیت همۀ فعالیت‌هایی که از طریق حساب
              کاربری مذکور و موبایل کاربر انجام شده و می‌شود به عهدۀ خود شخص خواهد بود.

            </li>
            <li>
              <span>2.10.</span>
              کاربران حق ندارند به سایر اشخاص حقیقی و حقوقی اجازه استفاده از حساب کاربری خود را بدهند یا حساب خود را
              به شخص حقیقی یا حقوقی دیگری منتقل کنند. در مورد کاربر شخص حقوقی نیز، مسئولیت‌های قانونی بهره‌برداری از
              خدمات ISOS متوجه مدیران اشخاص حقوقی خواهد بود.

            </li>
            <li>
              <span>2.11.</span>
              در شرایط خاصی ممکن است از کاربران برای استفاده از خدمات، درخواست احراز هویت شود، در این شرایط اگر
              کاربران اطلاعات کافی در اختیار شرکت قرار ندهند، شرکت می‌تواند حساب کاربری آن‌ها را مسدود و از ارائۀ
              خدمات به آن‌ها خودداری کند.

            </li>
            <li>
              <span>2.12.</span>
              ممکن است بنا به مورد و حسب صلاح‌دید شرکت، از کاربران درخواست شود که مدارکی را تکمیل و یا ارائه و اطلاعات
              موجود را اصلاح کنند. در این صورت، کاربر متعهد به ارائه اطلاعات و یا اصلاح اطلاعات درخواستی در مدت اعلام
              و درخواست شده از سوی شرکت خواهد بود. در غیر این صورت شرکت می‌تواند حساب کاربری شخص را مسدود کرده و از
              ارائۀ خدمات به او مذکور خودداری کند.

            </li>
            <li>
              <span>2.13.</span>
              اطلاعات کاربری کلیه کاربران نزد کاربر گیرنده اطلاعات، محرمانه بوده و هیچ یک از کاربران حق ندارند اطلاعات
              مربوط به حساب طرف مقابل را (اعم از مشخصات هویتی، شماره تماس، آدرس، عکس و غیره) ذخیره یا منتشر کند و در
              دسترس دیگران قرار دهد.

            </li>
          </ul>
        </li>
        <li>
          <h4>ماده 3. استفاده از ISOS</h4>
          <ul>
            <li>
              <span>3.1.</span>
              کاربران اعم از خدمت دهنده و گیرنده متعهد می‌شوند در زمان استفاده از اپلیکیشن کلیه مسائل اخلاقی را رعایت
              کنند.
            </li>
            <li>
              <span>3.2.</span>
              ا استفاده از خدمات ISOS، کاربران می‌پذیرند و موظف هستند که از خدمات ارائه شده برای انجام هیچ فعالیت
              غیر‌قانونی طبق قوانین جمهوری اسلامی ایران، خلاف شرع و مخالف با عرف جامعه استفاده نکنند.

            </li>
            <li>
              <span>3.3.</span>
              کاربران متعهد می‌شوند هیچ‌گاه از خدمات ISOS به صورتی استفاده نکنند که به ISOS و شرکای آن صدمه‌ای وارد
              شود. در صورتی که کاربران از امکانات فراهم شده در خدمات ISOS در جهت منافع شخصی و یا آسیب مادی یا معنوی به
              منافع شرکت سوءاستفاده کنند، شرکت ضمن داشتن حق قطع خدمات و غیرفعال کردن حساب کاربری آن‌ها، مجاز خواهد بود
              با مراجعه به مراجع ذی‌صلاح، نسبت به پیگیری قانونی از جمله مطالبه خسارات وارده اقدام کند.

            </li>
            <li>
              <span>3.4.</span>
              کاربران می‌پذیرند که شرکت ممکن است از ارسال پیامک، ایمیل و یا Push Notification به عنوان راه ارتباطی با
              آن‌ها استفاده کند. کاربران می‌توانند درخواست کنند که ارسال این پیام‌ها قطع شود، اما با انصراف از دریافت
              این پیام‌ها مشکلات احتمالی در استفاده از خدمات ISOS را می‌پذیرند و ممکن است اطلاعات مقتضی را به موقع
              دریافت نکنند.

            </li>
            <li>
              <span>3.5.</span>
              شرکت ممکن است کدهایی را به عنوان هدیه به کاربران ارائه کنند. با وارد کردن این کدها در نرم‌افزار، یا
              درصدی از هزینۀ خدمات کاسته و یا درصدی از هزینۀ خدمات پس از آن به کاربر برگردانده ‌و یا مبلغ مشخصی به
              حساب کاربر در اپلیکیشن ISOS اضافه ‌می‌شود. نحوۀ ارائه این خدمات و تعیین مهلت استفاده از آن‌ها منوط به
              تشخیص و صلاح‌دید شرکت یا طرف تجاری بوده و در صورت عدم تعلق کدهای مذکور، کاربران حق طرح هیچ‌گونه ادعا و
              اعتراضی در این خصوص نخواهند داشت.

            </li>
            <li>
              <span>3.6.</span>
              کاربران می‌پذیرند که اجازۀ انتقال یا فروش کدها را به اشخاص دیگر ندارند. همچنین کاربران مجاز نیستند این
              کدها را به صورت عمومی منتشر کنند – حتی اگر شرکت در فضاهای عمومی به نحوی که در دسترس عموم است، این کدها
              را منتشر کرده باشد – مگر اینکه شرکت رسماً این اجازه را به آن‌ها داده باشد.

            </li>
            <li>
              <span>3.7.</span>
              کاربران می‌پذیرند اعتباری که در حساب کاربری خود دارند مستقل از روش کسب قانونی آن (اگر در نتیجه افزایش
              اعتبار از طریق درگاه بانک یا وارد کردن کدهای تخفیفی که شرکت در اختیار آن‌ها قرار داده یا هر روش دیگری
              باشد) فقط برای استفاده از خدمات ISOS قابل استفاده است و امکان دریافت این وجه را به صورت نقد ندارد.

            </li>
            <li>
              <span>3.8.</span>
              کاربران می‌پذیرند کدهایی که جهت افزایش اعتبار یا کاهش هزینه ارائه خدمات به آن‌ها داده می‌شود، ممکن است
              منقضی شوند. از آنجایی که شرکت برای ارائه این کدها هزینه‌ای از کاربران دریافت نمی‌کند، این حق برای آن‌ها
              محفوظ است که مبلغ یا درصد تاثیر این کدها را حتی پس از انتشار بر اساس شرایط کد اختصاص داده شده، تغییر دهد
              یا در صورت لزوم آن‌ها را باطل کند. هم‌چنین در صورتی که شرکت تشخیص دهد، اعمال این کد به علت خطای فنی یا
              تخلف صورت گرفته و یا غیرقانونی بوده است، می‌تواند این کدها یا اعتباری که در نتیجۀ استفاده از آن به حساب
              کاربر افزوده شده را باطل یا حذف کند.

            </li>
            <li>
              <span>3.9.</span>
              مسئولیت تامین اینترنت و سخت‌افزار لازم و هم‌چنین پرداخت هزینه‌های مربوط به آن‌ها برای استفاده از خدمات
              ISOS به عهدۀ کاربران است.

            </li>
            <li>
              <span>3.10.</span>
              کاربران متعهد می‌شوند پس از اتمام استفاده از خدمات ارائه شده در اپلیکیشن، از اطلاعاتی که در نتیجۀ
              استفاده از خدمات ISOS کسب کرده‌اند، هیچ‌گونه استفاده‌ای نکنند. طبعاً ذخیره کردن اطلاعات کاربران در
              دستگاه تلفن همراه یا به هر شیوۀ دیگر مغایر با قوانین ISOS است. همچنین کاربران متعهد می‌شوند در طول زمان
              استفاده از خدمات و اپلیکیشن ISOS، فقط در شرایط ضروری و به میزان مورد نیاز از اطلاعاتی که از کاربران
              ارائه شده است، استفاده کنند. استفادۀ کاربران از این اطلاعات باید مطابق با عرف جامعه و قوانین جمهوری
              اسلامی ایران باشد. در هر صورت اطلاعات مذکور به صورت امانت در اختیار کاربران قرار گرفته است و در صورت نقض
              تعهدات مذکور، شرکت و شخص ذینفع مجاز به پیگیری حقوقی و کیفری موضوع خواهد بود.

            </li>
            <li>
              <span>3.11.</span>
              با استفاده از خدمات ISOS، کاربران می‌پذیرند که حین استفاده از اپلیکیشن ISOS، آن را وسیله‌ای برای تبلیغات
              و بازاریابی کالا و خدمات شخصی خود قرار ندهند و از هرگونه معرفی و عرضۀ محصولات و خدمات شخصی و یا متعلق به
              دیگران خودداری کنند.

            </li>
            <li>
              <span>3.12.</span>
              کاربران متعهد به رعایت همۀ شرایط و قوانین، اصول اسلامی، خلاقی و اجتماعی جمهوری اسلامی ایران در هنگام
              استفاده از ISOS هستند.

            </li>
            <li>
              <span>3.13.</span>
              کاربران می‌پذیرند و اعلام می‌کنند که متن حریم خصوصی ISOS را مطالعه کرده و مورد قبول ایشان است.

            </li>
            <li>
              <span>3.14.</span>
              کاربران می‌پذیرند که ISOS ارائه دهنده خدماتی است که ارتباط بین کاربران خدمت دهنده با خدمت گیرنده را جهت
              توافق بر ارائه خدمت مورد نظر فراهم می‌کند. در خدمات ISOS کاربر خدمت دهنده مختار است یک درخواست را بپذیرد
              یا رد کند، همچنین کاربر گیرنده خدمت مختار است پس از ارسال درخواست، مشخص شدن کاربر خدمت دهنده و قبل از
              ارائه خدمت مورد نظر اقدام به لغو درخواست کند. لذا هر درخواستی و قبول درخواستی که کاربران خدمت دهنده با
              خدمت گیرنده انجام می‌دهند باید به عنوان یک قرارداد لازم مستقل و رابطۀ قراردادی بین کاربران در نظر گرفته
              شود.

              <div>
                تبصره ۱- در صورت رد بی‌دلیل درخواست توسط کاربر خدمت گیرنده و یا لغو بی‌دلیل و به کرات درخواست توسط
                کاربر خدمت دهنده، شرکت می‌تواند نسبت به غیرفعال کردن حساب کاربری آن‌ها به صورت موقت یا دائم اقدام کند.

              </div>
            </li>
            <li>
              <span>3.15.</span>
              خدمات ISOS ممکن است با مشارکت خدمات شرکت‌ها یا طرف تجاری دیگری ارائه شود که شرکت کمک رسان کنترلی روی
              آن‌ها ندارد. در چنین شرایطی کاربران می‌پذیرند که این خدمات، شرایط و قوانین مختص خود را دارند و ISOS
              مسئولیتی در خصوص قوانین و خدمات این شرکت‌ها ندارد.

            </li>
            <li>
              <span>3.16.</span>
              از آنجایی که ممکن است شخص یا اشخاصی غیر از کاربر خدمت گیرنده از حساب کاربری وی استفاده کنند، مسئولیت
              پرداخت هزینه‌های مترتبه متوجه کاربر خدمت گیرنده است. هم‌چنین لازم به ذکر است که هرگونه مسئولیت قانونی
              استفاده‌کننده یا خدمت گیرنده از حساب کاربری، نسبت به کاربر خدمت دهنده و شرکت متوجه کاربر گیرنده است و
              بدین ترتیب کاربر مذکور و گیرندگان واقعی خدمت مورد نظر نسبت به شرکت دارای مسئولیت تضامنی هستند.

            </li>
            <li>
              <span>3.17.</span>
              کاربر خدمت گیرنده متعهد می‌شود که به هیچ عنوان با حساب کاربری خود برای اشخاص زیر ۱۸ سال درخواست ارسال
              نکند مگر با حضور همزمان خود یا شخص مورد نظر. کاربر خدمت دهنده مکلف به لغو (امتناع از انجام) چنین خدماتی
              است. در غیر این صورت، مسئولیت ارائه خدمت بر عهده کاربر خدمت دهنده و همچنین جبران خسارات وارده احتمالی بر
              عهده کاربر خدمت گیرنده است.

            </li>
          </ul>
        </li>
        <li>
          <h4>ماده 4. هزینه ها و پرداخت</h4>
          <ul>
            <li>
              <span>4.1.</span>
              کاربران می‌پذیرند امکان استرداد هزینه‌هایی که بر اساس شرایط استفاده از خدمات اپلیکیشن به شرکت
              می‌پردازند، وجود ندارد. شرکت مغایرت در هزینه اعلامی و پرداخت شده را تنها در ۴۸ ساعت اولیه رسیدگی
              می‌کنند. بنابراین کاربران باید موضوع را در همان ۴۸ ساعت اولیه به اطلاع شرکت برسانند.

            </li>
            <li>
              <span>4.2.</span>
              کاربران می‌پذیرند هزینۀ ارائه خدمات از طرف شرکت مشخص می‌شود و پس از استفاده از آن نمی‌توانند اعتراضی
              نسبت به هزینۀ اعلام شده داشته باشند. بدیهی است از آنجا که کاربران مجاز به عدم پذیرش هزینۀ اعلام شده از
              سوی شرکت هستند، در صورت قبول، متعهد به پرداخت آن می‌شوند. به همین منظور کاربران با قبول این شرایط و
              قوانین اختیار دریافت و وصول مبالغ مورد مطالبۀ شرکت را از محل اعتبار کاربری خود به شرکت می‌دهند.

            </li>
            <li>
              <span>4.3.</span>
              پرداخت هزینۀ خدمت ارائه شده و سایر هزینه‌های اعلام شده توسط ISOS فقط از طریق روش‌هایی که توسط شرکت مشخص
              می‌شود می‌تواند صورت گیرد. این روش‌ها عبارتند از: پرداخت از طریق اعتبار موجود در حساب کاربری، کیف پول،
              پرداخت اینترنتی جهت افزایش موجودی حساب کاربری و یا از طریق USSD برای افزایش اعتبار. روش‌های پرداخت محدود
              به موارد مذکور بوده است. هرگونه روش دیگری نظیر کارت به کارت، تهاتری و غیره مورد تایید شرکت نیست و در این
              صورت شرکت هیچ‌گونه مسئولیتی بر عهده نخواهد داشت.

            </li>
            <li>
              <span>4.4.</span>
              کاربران می‌پذیرند که باید هزینۀ ارائه خدمات را مستقل از هرگونه مشکلی که ممکن است در برنامۀ ISOS جهت
              پرداخت آنلاین به وجود آید به شرکت پرداخت کند. در صورتی که به دلیل مشکلی در برنامه یا مشکلات ارتباطی از
              جمله اینترنت و شبکۀ تلفن همراه امکان پرداخت از طریق نرم‌افزار فراهم نشود، کاربران همچنان موظف به پرداخت
              هزینه‌های ارائه خدمات خواهند بود. در این شرایط پرداخت هزینه ممکن است با هماهنگی کاربران با مرکز تماس
              شرکت به روش‌های دیگر صورت پذیرد. در صورتی‌ که کاربران به هر دلیلی از جمله مشکلات نرم‌افزار پرداخت را
              انجام نداده باشند، شرکت یا طرف تجاری حق دارند هزینه را از اعتبار کاربران کسر کنند و در صورت عدم پرداخت
              حساب کاربری آن‌ها مسدود خواهد شد.

            </li>
            <li>
              <span>4.5.</span>
              کاربر خدمت گیرنده می‌پذیرند جبران هرگونه اقدامی علیه کابر خدمت دهنده در زمان استفاده از خدمات برعهده
              ایشان است. در چنین مواردی شرکت حق دارد این هزینه‌ها را از اعتبار موجود در حساب ISOS از کاربر خدمت گیرنده
              کسر یا از او وصول کند. در صورتی که اعتبار موجود نزد شرکت به میزان خسارات وارده و هزینه‌های مترتب بر آن
              نباشد، کاربر خدمت گیرنده و یا شرکت مجاز به پیگیری وصول مطالبات مذکور خواهند بود. همچنین چنان‌چه کاربر
              خدمت گیرنده به فحاشی، توهین و سایر رفتارهای خلاف قانون علیه کاربر خدمت دهنده اقدام کند حق مراجعه کاربر
              خدمت دهنده به مراجع ذی‌صلاح محفوظ است. علاوه بر آن شرکت حق مراجعه به مراجع ذی‌صلاح را به دلیل لطمه به
              شهرت و اعتبار تجاری خود خواهد داشت.

            </li>
            <li>
              <span>4.6.</span>
              کاربران ملزم به پرداخت کلیه هزینه‌های اعلام شده توسط ISOS هستند.
            </li>
          </ul>
        </li>
        <li>
          <h4>ماده 5. مسئولیت ISOS</h4>
          <ul>
            <li>
              <span>5.1.</span>
              شرکت با تمام امکانات خود از جمله مرکز تماس ۲۴ ساعته، واحد رسیدگی به شکایات و فرایند میانجیگری، تمامی
              تلاش خود را برای جلب رضایت کاربران و رفع هرگونه اختلاف احتمالی بین آنها در زمان استفاده از خدمات ISOS به
              کار می‌گیرند.

            </li>
            <li>
              <span>5.2.</span>
              شرکت با استفاده از نظارت‌های خود و انجام نظرسنجی از کاربران در پایان خدمات ارائه شده تلاش می‌کند خدمات
              با کیفیت مطلوب به کاربران ارائه دهد.

            </li>
            <li>
              <span>5.3.</span>
              شرکت برای فراهم کردن امکان استفاده از خدمات نرم‌افزاری توسط دستگاه‌های کاربران تلاش می‌کنند، اما تضمینی
              نسبت به اجرا شدن آن روی همۀ دستگاه‌ها یا شبکه‌های ارتباطی (اینترنت، شبکۀ تلفن‌ همراه و …) نخواهد داد.

            </li>
            <li>
              <span>5.4.</span>
              اطلاعات کاربران به عنوان اطلاعات محرمانه نزد شرکت محافظت شده و دسترسی به آن توسط اشخاص ثالث ممنوع بوده،
              مگر برابر قانون و تصمیم مقام ذیصلاح قضایی. بنابراین در موارد قانونی و هم‌چنین درخواست و صدور دستور مقتضی
              از سوی مراجع قضایی، شرکت مکلف به ارائۀ اطلاعات کاربران به مراجع مذکور است. لازم به توضیح است که ارائه
              اطلاعات کاربری صرفا از طریق اپلیکیشن امکان‌پذیر است و به منظور حفظ امنیت اطلاعات کاربران و اصل محرمانگی،
              ارائه اطلاعات کاربری به خود کاربر و اشخاص ثالث به صورت تلفنی و حضوری مقدور نیست، مگر به حکم قانون.

            </li>
            <li>
              <span>5.5.</span>
              نان‌چه هر یک از کاربران در زمان استفاده از خدمات آن با مشکلی مواجه شوند، برابر شرایط فوق می‌توانند شکایت
              خود را از طریق مرکز تماس ۲۴ ساعته و واحد رسیدگی به شکایات شرکت کمک رسان مطرح کنند. شرکت یا طرف تجاری
              پیگیری لازم را بنا به مورد انجام خواهد داد و تلاش خود را جهت حل و فصل اختلافات به‌عمل خواهد آورد.

            </li>
            <li>
              <span>5.6.</span>
              همۀ اطلاعات مورد نیاز کاربران درباره نحوه و شرایط ارائه خدمات ISOS و استفاده از نرم‌افزار‌های
              ارائه‌دهنده خدمات اعم از خدمت گیرنده و خدمت دهنده، توسط شرکت در نرم‌افزار قرار داده شده است. اشخاص
              می‌توانند با مراجعه به قسمت مذکور و مطالعۀ آن‌ کاملا با شرایط ذکر شده و خدمات آشنا شوند. همچنین همکاران
              شرکت در مرکز تماس به صورت ۲۴ ساعته پاسخگوی هرگونه ابهام و سوال کاربران هستند.

            </li>
            <li>
              <span>5.7.</span>
              مسئولیت شرکت فقط در فرضی متصور است که کاربران از طریق اپلیکیشن اقدام به اخذ خدمات از جمله ارسال درخواست
              و قبول توسط کاربر دیگر کرده باشند. لذا اگر کاربر خدمت دهنده و کاربر خدمت گیرنده که در اپلیکیشن ثبت‌نام
              کرده‌اند، خارج از اپلیکیشن با یکدیگر بر انجام ارائه خدمت توافق کنند، هیچ‌گونه مسئولیتی متوجه شرکت نخواهد
              بود.

            </li>
          </ul>
        </li>
        <li>
          <h4>ماده 6. مسئولیت کاربران</h4>
          <ul>
            <li>
              <span>6.1.</span>
              مسئولیت همۀ اقدامات و افعال کاربران که ناشی از عدم رعایت قوانین موضوعه جمهوری اسلامی ایران از جمله قانون
              مجازات اسلامی (ارتکاب جرم در هنگام استفاده از خدمات) و قوانین مدنی (ورود خسارت به اموال کاربران) باشد،
              برعهدۀ شخص متخلف بوده و شرکت هیچ مسئولیتی در قبال اعمال و افعال فوق نداشته و نخواهند داشت. شرکت می‌تواند
              ضمن غیر فعال کردن حساب کاربری متخلف، از طریق مراجع ذی‌صلاح، اقدامات قانونی را علیه نامبرده نسبت به احقاق
              حقوق قانونی خود به عمل آورد.

            </li>
            <li>
              <span>6.2.</span>
              هرگونه اقدامی از سوی کاربران که منجر به ورود خسارت به شهرت، اعتبار و دارایی‌های شرکت شود تخلف از شرایط
              قراردادی و قانونی تلقی خواهد شد. شرکت این حق را برای خود محفوظ می‌داند که حساب کاربر متخلف را مسدود کند
              و در صورت نیاز اقدامات قانونی لازم را علیه متخلف به‌عمل آورد و خسارت و حقوق قانونی خود را مطالبه کند.

            </li>
            <li>
              <span>6.3.</span>
              مسئولیت رعایت همۀ قوانین و مقررات حوزه سلامت اعم از مقررات وزارت بهداشت، معاونت درمان، نظام پزشکی و آئین
              نامه و دستورالعمل های صادره برعهده کاربران است و شرکت در این خصوص مسئولیتی نخواهد داشت.

            </li>
            <li>
              <span>6.4.</span>
              کاربران متعهد می‌شوند در صورت مواجهه با نقض هر کدام از تعهدات حین ارائه خدمت و یا نقض قوانین موضوعه
              جمهوری اسلامی ایران از جمله قانون مجازات اسلامی (ارتکاب جرم) و قوانین مدنی (ورود خسارت به اموال کاربران)
              بدون مداخله مستقیم، از انجام هر اقدامی بر اساس صلاح‌دید شخصی پرهیز کنند. کاربر خدمت دهنده در صورت نیاز
              می‌تواند ارائه خدمت خود را متوقف و موضوع را با پشتیبانی شرکت در میان بگذارد، کاربر خدمت گیرنده نیز
              می‌تواند تا برقراری تماس با پشتیبانی از کاربر خدمت دهنده درخواست توقف خدمت مورد نظر را داشته باشد. در
              نهایت پشتیبانی شرکت تصمیم مقتضی را بر مبنای وضعیت اخذ می‌کند و در صورت لزوم موضوع با نیروی انتظامی و
              مراجع ذیصلاح در میان گذاشته می‌شود.

              <div>
                تبصره ۵- قطع ارائه خدمت توسط کاربر خدمت دهنده بدون رضایت کاربر گیرنده خدمت و قبل از اعلام نظر پشتیبانی
                شرکت ممنوع است.
              </div>
            </li>
            <li>
              <span>6.5.</span>
              کاربر خدمت گیرنده مطلع است و می‌پذیرد که در زمان نصب نرم‌افزار یا به‌روزرسانی آن، نسبت به فعال‌سازی یا
              غیرفعال‌سازی گزینه‌ی دسترسی به «اطلاعات آماری» در بخش تنظیمات نرم‌افزار اقدام می‌کند. کاربر گیرنده خدمت
              در فعال‌سازی یا غیرفعال‌سازی گزینه‌ دسترسی به «اطلاعات آماری» کاملا مختار است. لازم به توضیح است که
              دسترسی به اطلاعات آماری در تحقیقات بازاریابی و برای بهبود تجربه ارائه خدمت به کار می‌رود و به صورت
              خودکار فعال است.

            </li>
            <li>
              <span>6.6.</span>
              کاربران مکلف به رعایت کلیه شرایط و قوانین منتشر شده در ISOS هستند و در صورت نقض تعهدات و شرایط مذکور
              مسئول پذیرش کلیه آثار ناشی از تخلف در قبال ISOS و اشخاص ثالث خواهند بود.

            </li>
            <li>
              <span>6.7.</span>
              کاربران (اعم از کاربر خدمت دهنده یا کاربر خدمت گیرنده) باید توجه داشته باشند هرگونه توصیه و مشاوره ارائه
              شده توسط کاربر خدمت دهنده فقط مختص شخصی است که درخواست مشاوره داشته و با توجه به تفاوت شرایط هر شخص نسبت
              به شخص دیگر، کاربران باید از عمل به مشاوره و توصیه ارائه شده به شخص دیگر خودداری کند.

            </li>
            <li>
              <span>6.8.</span>
              کاربران باید توجه داشته باشند سامانه ISOS برای استفاده و ارائه خدمات در صورت رخداد موارد اورژانس پزشکی،
              اورژانس اجتماعی و هر مورد اورژانسی دیگر یا مواردی که در آن حضور فیزیکی متخصص و یا معاینه بالینی یا
              اندازه گیری علائم حیاتی نیاز باشد، ایجاد نشده و در این موارد خدماتی ارایه نمی‌کند. لازم به ذکر است
              کاربران در چنین مواردی باید با اورژانس شهر خود یا هر مرجع در نظر گرفته شده برای رسیدگی به آن مورد
              اورژانسی تماس حاصل بگیرند (مورد اورژانس به مواردی همچون تصادفات، خونریزی، صدمات ناشی از ضرب و جرح، موارد
              مسمومیت، کاهش سطح هوشیاری، هر موردی مشکوک به انواع سکته، آسیب‌‌های ناشی از خودکشی و هر آنچه که عرفا به
              نام مورد اورژانسی شناخته می‌شود یا نیاز به فوریت‌های پزشکی دارد گفته می‌شود.لازم به ذکر است کلیه موارد
              مطرح شده در این بند تمثیلی بوده و جنبه حصری ندارد و محدود به موارد ذکر شده نیست.

            </li>
            <li>
              <span>6.9.</span>
              کاربر خدمت دهنده در ارائه توصیه و مشاوره موظف به رعایت کلیه قوانین، دستورالعمل‌ها، همچنین عرف و رویه آن
              حوزه است. همچنین کاربران باید توجه داشته باشند شرایط زمانی (مثلا توصیه باید در زمانی خاصی انجام شود)،
              شرایط عمومی و اختصاصی (شرایط حاکم بر وضعیت جسمانی یا روانی) هر فرد در هر توصیه و مشاوره ارائه شده از
              جانب کاربر خدمت دهنده و کاربر خدمت گیرنده باید مورد توجه قرارگیرد. کمک رسان صرفا یک بستر نرم‌افزاری
              برقرار کننده ارتباط میان کاربر متخصص و کاربر عادی است و هیچ گونه مسئولیتی در قبال محتوای مشاوره انجام
              شده ندارد.
            </li>
            <li>
              <span>6.10.</span>
              کاربر خدمت دهنده حق ارائه مشاوره در حوزه غیر تخصصی خود را نداشته همچنین از کاربران درخواست می‌شود در
              صورت مواجهه با چنین مواردی نسبت به ارائه گزارش تخلف به پشتیبانی اقدام کنند.

            </li>
          </ul>
        </li>
        <li>
          <h4>ماده 7. موارد فنی</h4>
          <ul>
            <li>
              <span>7.1.</span>
              کاربران مجاز نیستند هیچ‌گونه تلاشی در جهت استخراج سورس کد Source Code نرم‌افزارISOS شامل دیکامپایل
              Decompile، مهندسی معکوس Reverse Engineering یا فعالیت‌های مشابه انجام دهند، مگر اینکه اجازۀ چنین کاری به
              صورت قانونی صادر شده باشد.
            </li>
            <li>
              <span>7.2.</span>
              کاربران مجاز نیستند با استفاده از روش‌های Framing یا Mirroring و روش‌های دیگر، نسخۀ دیگری از خدمات ISOS
              را روی سرورهای دیگر ارائه کنند. همچنین کاربر مجاز نیست در نرم‌افزاریا در روش ارائۀ خدمات تغییر یا اخلالی
              ایجاد کند.

            </li>
            <li>
              <span>7.3.</span>
              کاربران مجاز نیستند نرم‌افزار یا اسکریپتی با هدف ایندکس‌گذار (Indexing)، مطالعه یا هرگونه فعالیت
              داده‌کاوی روی خدمات اجرا کنند.
            </li>
            <li>
              <span>7.4.</span>
              کاربران مجاز نیستند هیچ فعالیتی در راستای حصول دسترسی غیرقانونی و غیرمتداول به هیچ بخش از اپلیکیشن ISOS
              انجام دهند.

            </li>
          </ul>
        </li>
        <li>
          <h4>ماده 8. محتوای تولید شده توسط کاربران</h4>
          <p>
            شرکت ممکن است در شرایطی به کاربران اجازه دهند که در وب سایت، نرم‌افزار موبایل، شبکه‌های اجتماعی و یا سایر
            بخش‌های این سامانه، عکس، متن، فایل صوتی، فایل تصویری یا فایل‌های مشابه منتشر کنند. مالکیت این مطالب همچنان
            متعلق به کاربران خواهد بود. اما با ثبت آن‌ها در خدمات‌ مرتبط به ISOS، کاربران این حق را به شرکت می‌دهند که
            اطلاعاتی را که از حالت محرمانه خارج شده است، منتشر کنند و یا به اشخاص حقیقی یا حقوقی دیگر منتقل کنند.

          </p>
        </li>
        <li>
          <h4>ماده 9. قطع خدمات</h4>
          <p>
            شرکت یا طرف تجاری به هر دلیلی به این نتیجه برسد که حضور هر یک از کاربران اعم می‌تواند امنیت و آرامش کسب و
            کار و یا دیگر کاربران را در معرض تهدید قرار دهد، طبق صلاحدید خود مجاز به مسدود کردن حساب کاربر مذکور یا
            عدم اجازه استفاده از خدمات به صورت موقتی یا دائم است. اگر کاربران تعهدات این شرایط و قوانین را رعایت نکنند
            یا دسترسی آنها به خدمات ISOS خطرناک باشد یا مطلوب نباشد، شرکت حق حذف دسترسی کاربر مذکور را به خدمات را
            دارد. در این صورت، کاربر حق طرح هیچگونه ادعا و یا اعتراضی را نخواهد داشت.
          </p>
        </li>
        <li>
          <h4>ماده 10. توافق از راه دور</h4>
          <ul>
            <li>
              <span>10.1.</span>
              کاربران توافق می‌کنند برای مبادلۀ آسان اطلاعات و انجام توافقات جدید، همۀ تغییرات و الحاقیه‌های شرایط و
              قوانین حاضر اعم از تغییر و اضافه کردن شروط آن، تنظیم قراردادها، اخطاریه‌ها و ابلاغیه‌های مربوط به شرکت،
              از طریق اپلیکیشن اسنپ دکتر و سوپر اپ اسنپ به‌عمل آید. کاربران ضمن پذیرش شرایط و روند اجرایی توافق از راه
              دور و به صورت الکترونیکی، موافقت خود را نسبت به اعمال هرگونه شرایط و توافق جدید از طریق فضای مجازی و به
              صورت الکترونیکی اعلام می‌کنند.
            </li>
            <li>
              <span>10.2.</span>
              حساب کاربری متعلق به شخص کاربر و استفاده از آن قائم به شخص کاربر و غیر قابل انتقال است. کلیه مسئولیت‌های
              مربوط به حفظ و حراست از حساب کاربری برعهدۀ کاربر دارنده حساب کاربری است. کاربران ضمن پذیرش کلیه مسئولیت
              ناشی از استفاده از حساب کاربری، همۀ داده پیام‌های صادره از طریق حساب کاربری مذکور اعم از شکل، عبارت،
              تاییدیه، انتخاب اشکال و تایید آن‌ها را منتسب به خود دانسته و هرگونه ادعایی نسبت به انکار، تردید و جعل
              داده پیام‌های ارسال شده از ناحیۀ کاربران غیر قابل استماع و مردود است.
            </li>
            <li>
              <span>10.3.</span>
              کاربران می‌پذیرند هرگونه رویه، اشکال، عبارات مبین تایید و اعلام قبولی و تصدیق داده پیام‌های صادره از طرف
              شرکت به منزله امضا الکترونیکی و قبولی محسوب می‌شود و هرگونه ادعایی نسبت به امضا و قبولی مذکور غیر قابل
              استماع و مردود است.
            </li>
            <li>
              <span>10.4.</span>
              کاربران با عضویت در خدمات ISOS و استفاده از آن قصد و اراده خود را نسبت به پذیرش انعقاد هرگونه اقدام و
              عمل حقوقی از جمله عقود و معاملات از راه دور و از طریق سامانۀ رایانه‌ای و الکترونیکی اعلام می‌کنند.
            </li>
            <li>
              <span>10.5.</span>
              کلیه رویه‌های حقوقی و فنی اعم از احراز هویت کاربر، ایجاب و قبول، امضای الکترونیکی و تاییدیه‌های مرتبط
              ارائه‌شده توسط شرکت جهت انعقاد قرارداد الکترونیکی با کاربران از طریق اپلیکیشن، مورد قبول کاربران بوده و
              هرگونه روش فنی جهت تنظیم قرارداد از جمله ایجاب و قبول به مثابه توافق کتبی است. هرگونه ادعایی اعم از
              انکار و تردید نسبت به قرارداد الکترونیکی غیر قابل استماع و مردود است.
            </li>
            <li>
              <span>10.6.</span>
              با توجه به انعقاد قرارداد حاضر به صورت الکترونیکی و در فضای مجازی، کاربران موافقت خود را نسبت به تعیین
              اقامتگاه شرکت و طرف تجاری، به عنوان محل انعقاد قرارداد، اعلام می‌کند.
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="dialog-actions-container">
  <mat-dialog-actions class="justify-content-between align-items-center" *ngIf="data === false">
    <div class="form-group d-flex align-items-center">
      <input type="checkbox" id="acceptRulesBox" style="border-color: gray" [checked]="acceptRules"
             (change)="acceptRules = !acceptRules">
      <label for="acceptRulesBox" class="mb-0 pr-1" style="color: gray; font-size: 12px;">شرایط و مقررات استفاده از سایت
        را می‌پذیرم.</label>
    </div>
    <button class="btn btn-primary" [disabled]="!acceptRules" (click)="confirm()">می‌پذیرم</button>
  </mat-dialog-actions>
</div>
