import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-accept-rules',
  templateUrl: './accept-rules.component.html',
  styleUrls: ['./accept-rules.component.css']
})
export class AcceptRulesComponent implements OnInit {

  acceptRules: boolean = false;

  constructor(public dialog: MatDialogRef<AcceptRulesComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

  confirm(): void {
    this.dialog.close(true);
  }

}
