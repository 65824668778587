import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable()

export class JwtInterceptor implements HttpInterceptor{

    constructor(private authService: AuthService){}
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
        
        const user = this.authService.userValue;
        const isLoggedIn = user && user.jwtToken;
        const isIdentityUrl = request.url.startsWith(environment.identityUrl)
        
        if(isLoggedIn && isIdentityUrl){
            request = request.clone({
                setHeaders: { Authorization: `Bearer ${user.jwtToken}` }
            })
        }
        return next.handle(request)
    }
}