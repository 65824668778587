import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-sos-radio',
  templateUrl: './sos-radio.component.html',
  styleUrls: ['./sos-radio.component.css']
})
export class SosRadioComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() controlName: string;
  @Input() radioId: any = 'radioID_0'
  @Input() label: string = 'label'
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<boolean>()
  constructor() { }

  ngOnInit(): void {
    this.form.addControl(this.controlName, new FormControl(this.value));
  }

  onValueChange () {
    this.form.controls[this.controlName].setValue(this.value);
    const temp = this.form.controls[this.controlName].value
    const tempValue = temp? false : true
    this.valueChange.emit(tempValue)
  }

}
