import { EventEmitter, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr'
import { BehaviorSubject, from } from 'rxjs';
import { take } from 'rxjs/operators';
import { HttpService } from './http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SignalrService {
  
  get baseUrl(): string {
    return environment.baseUrl
  }
  private connection: HubConnection;
  private connectionURL = `${this.baseUrl}/sig/hub/ChatHub`;
  public messages: Array<any> = [];
  public consultantVisitId: BehaviorSubject<number>;
  public rTCPeerConfiguration$: BehaviorSubject<any>;
  public isDoctor: BehaviorSubject<boolean>;
  private user: any;

  messageReceived = new EventEmitter<any>();
  feedBackRecieved = new EventEmitter<any>();
  callRequested = new EventEmitter<any>();
  callResponed = new EventEmitter<any>();
  webRTCMessage = new EventEmitter<any>();
  hangUpRecieved = new EventEmitter<any>();
  requestReceived = new EventEmitter<any>();
  responseReceived = new EventEmitter<any>();
  notifReceived = new EventEmitter<any>();
  walletBalnce = new EventEmitter<any>();

  constructor(private http: HttpService) {
    this.consultantVisitId  = new BehaviorSubject<number>(0);
    this.rTCPeerConfiguration$ = new BehaviorSubject<any>({
      // iceServers: [
      //   {
      //     urls: 'stun:363dae5f-7769-4e53-983b-bd207c25b28f.hsvc.ir'
      //   },
      //   {
      //     urls: ['turn:363dae5f-7769-4e53-983b-bd207c25b28f.hsvc.ir'],
      //     username: 'test',
      //     credential: 'test123'
      //   }
      // ]
    });
  }

  public connect = () => {
    this.startConnection();
    this.addListeners();
    this.getIceServerConfiguration();
  }

  public disconnect = () => {
    this.endConnection();
  }

  private getConnection(): HubConnection {
    this.user = JSON.parse(localStorage.getItem('sosUser'));
    const isDoctor = this.isUserADoctor(); // (this.user['role'] === 'Doctor')? true : false;
    const isPatient = this.isUserAPatient();
    const visitId = this.consultantVisitId.value;
    return new HubConnectionBuilder()
    .configureLogging(signalR.LogLevel.Information)
    .withUrl(`${this.connectionURL}?globalUserId=${this.user['sub']}&isDoctor=${isDoctor}&isPatient=${isPatient}&visitId=${visitId}`,
    {
      accessTokenFactory: () => {
        return this.user['jwtToken'];
      },
      withCredentials: false
    })
    .withAutomaticReconnect()
    .build();
  }

  private isUserADoctor(): boolean {
    if (!this.user || !this.user.role) return null
    else {
      return this.user['role'].includes('Doctor');
    }
  }

  private isUserAPatient(): boolean {
    if (!this.user || !this.user.role) return null
    else {
      return this.user['role'].includes('Patient');
    }
  }

  private startConnection() {
    this.connection = this.getConnection();
    this.connection.start()
    .then(() => console.log('connection started'))
    .catch((err) => console.log('connection error', err))
  }

  private async endConnection() {
    const isDoctor = (!!this.user && this.user['role'] === 'Doctor');
    if (isDoctor) {
      this.signOut(this.user['sub'])
      .then(
        () => this.stopConnection()
      )
      .catch(
        (err) => console.log(err)
      )
    }
    else this.stopConnection()
  }

  private stopConnection() {
    this.connection.stop()
      .then(() => {
        this.connection = undefined
        console.log('connection ended')
      })
      .catch((err) => console.log('disconnection error'))
  }

  private addListeners() {
    this.connection.on('GetMessage', (data: any, user: any, consultantId: number) => {
      this.messageReceived.emit({msg: data, user: user, consultantId: consultantId})
      this.messages.push(data);
    })
    this.connection.on("newUserConnected", _ => {
      console.log("new user connected")
    })
    this.connection.on('ShowFeedbackPannel', (sender: any, consultant: any) => {
      this.feedBackRecieved.emit({sender, consultant})
    })
    this.connection.on('GetCallRequest', (senderGlobalId: string) => {
      this.callRequested.emit(senderGlobalId)
    })
    this.connection.on('GetCallResponse', (response: boolean) => {
      this.callResponed.emit(response)
    })
    this.connection.on('GetOffer', (sdpData, senderGlobalId, senderFullName) => {
      this.webRTCMessage.emit({
        type: 'offer',
        offer: sdpData,
        senderGlobalId,
        senderFullName
      });
      // this.offerReceived.emit({sdpData, senderGlobalId, senderFullName})
    });
    this.connection.on('GetAnswer', (sdpData, senderGlobalId, senderFullName) => {
      this.webRTCMessage.emit({
        type: 'answer',
        answer: sdpData,
        senderGlobalId,
        senderFullName
      });
      // this.answerReceived.emit({sdpData, senderGlobalId, senderFullName})
    });
    this.connection.on('getCandidate', (candidateData) => {
      this.webRTCMessage.emit({
        type: 'candidate',
        candidate: candidateData
      });
    });
    this.connection.on('GetHangupCall', () => {
      this.hangUpRecieved.emit(true);
    });
    this.connection.on('MedicalRecordAccessRequest', (medicalRecordId:any, doctorUserGlobalId:any) => {
      this.requestReceived.emit({record: medicalRecordId, id: doctorUserGlobalId})
    });
    this.connection.on('MedicalRecordAccessResponse', (medicalRecordId:any, PatientGlobalId:any) => {
      this.responseReceived.emit({record: medicalRecordId, id:PatientGlobalId})
    });
    this.connection.on('ShowNewBooking', () => {
      this.notifReceived.emit(true)
    });
    this.connection.on('UpdateBalanceForUser', (data: any) => {
      this.walletBalnce.emit(data);
    });
  }

  private getIceServerConfiguration() {
    //this.http.getConsultantDataWithAction('Voip', 'GetIceServerUrls')
    this.http.getData('Config', 'GetWebRtcConfig')
    .pipe(take(1))
    .subscribe(
      res => {
        const result = {
          iceServers: [
            {
              urls: res?.stunServerUrl
            },{
              urls: [res?.turnServerUrl],
              username: res?.turnServerUsername,
              credential: res?.turnServerPassword
            }
          ]
        }
        this.rTCPeerConfiguration$.next(result)
      },
      err => {
        console.log('امکان برقراری تماس تصویری وجود ندارد!')
      }
    )
  }

  //chat methods
  private async signOut(userGlobalId: string) {
    let promise = this.connection.invoke('SignOut', userGlobalId)
    .then(() => console.log('connection ended'))
    .catch((err) => console.error(err))

    const signout = await promise
    return signout
  }

  public sendMessageToHub(message: string, receiverUserGlobalId: string, senderUserGlobalId: string, isDoctor: boolean, consultantVisitId: number) {
    let promise = this.connection.invoke('SendMessageToUser', message , receiverUserGlobalId, senderUserGlobalId, isDoctor, consultantVisitId)
    .then(() => console.log('',message))
    .catch(err => {
      console.error(err);
    });

    return from(promise);
  }

  public sendMessageAPI(message: string, type: number, id: any){
    this.http.postConsultantData('Chat', {
      messageSenderType: type,
      consultantVisitId: id,
      messageHtml: message
    }).subscribe(
      res => { return true },
      err => { return false }
    )
  }

  public GetMessage(message: string){
    this.connection.on('GetMessage', (data: any) => {
      console.log(message, data)
    })
  }

  public SendBookingNotifToDoctor(doctorGlobalId: string){
    let promise = this.connection.invoke('ShowNewBookingToDoctor', doctorGlobalId)
    .then(() => console.log('notif sent!'))
    .catch(err => {
      console.error(err);
    });

    return from(promise);
  }

  //feedback methods
  public showFeedbackPanelToUser(receiverUserGlobalId: string, senderUserGlobalId: string, consultantVisitId: number){
    let promise = this.connection.invoke('ShowFeedbackPannelToUser' , receiverUserGlobalId, senderUserGlobalId, consultantVisitId)
    .then(() => console.log('finish consultant with id:',consultantVisitId))
    .catch(err => console.error(err))

    return from(promise)
  }

  //video call methods
  public sendCallRequestToHub(recieverGobalId:string) {
    let promise = this.connection.invoke('CallUser', this.user['sub'], recieverGobalId)
    .then(() => console.log('call request sent to hub'))
    .catch((err) => console.log(err))

    return from(promise)
  }

  public sendCallResponseToHub(recieverGobalId:string, response:boolean) {
    let promise = this.connection.invoke('CallResponse', recieverGobalId, response)
    .then(() => console.log('call request sent to hub'))
    .catch((err) => console.log(err))

    return from(promise)
  }

  public sendOfferToHub(sdpData: string, receiverUserGlobalId: string) {
    let promise = this.connection.invoke('SendSdpOffer', sdpData, this.user['sub'], this.user['name'], receiverUserGlobalId)
    .then(() => console.log('SDP offer sent to hub'))
    .catch((err) => console.log(err))

    return from(promise)
  }

  public sendAnswerToHub(sdpData: string, receiverUserGlobalId: string) {
    let promise = this.connection.invoke('SendSdpAnswer', sdpData, this.user['sub'], this.user['name'], receiverUserGlobalId)
    .then(() => console.log('SDP answer sent to hub'))
    .catch((err) => console.log(err))

    return from(promise)
  }

  public sendCandidateToHub(candidateData: string, destinationUserGlobalId: string) {
    let promise = this.connection.invoke('CandidateRequest', destinationUserGlobalId, candidateData)
    .then(() => console.log('candidate data sent to hub'))
    .catch((err) => console.log(err))

    return from(promise)
  }

  public sendHangUpToHub(destinationUserGlobalId: string) {
    let promise = this.connection.invoke('HangupCall', destinationUserGlobalId)
    .then(() => console.log('hangup sent to hub'))
    .catch((err) => console.log(err))

    return from(promise)
  }

  //access request to medical records
  public sendAccessRequestToHub(medicalRecordId: number, patientUserGlobalId: string, doctorUserGlobalId: string) {
    let promise = this.connection.invoke('SendRequestToPatient', medicalRecordId , patientUserGlobalId, doctorUserGlobalId)
    .then(() => console.log('access request sent!'))
    .catch(err => {
      console.error(err);
    });

    return from(promise);
  }

  public sendRequestResponseToHub(medicalRecordId: string, accpetStatus:boolean, doctorGlobalId: string, PatientGlobalId: string) {
    let promise = this.connection.invoke('SendResponseToDoctor', medicalRecordId , doctorGlobalId, PatientGlobalId)
    .then(() => console.log('request response sent!'))
    .catch(err => {
      console.error(err);
    });

    return from(promise);
  }

}
