import {NgModule} from '@angular/core';
import {RouterModule} from "@angular/router";
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ImageCropperModule} from 'ngx-image-cropper';
import {CoreModule} from 'src/app/core/core.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {PersianDate} from 'src/app/shared/persian-date/persian-date.pipe';
import {PersianShortDate} from 'src/app/shared/persian-date/persian-short-date.pipe';
import {PasswordShowHideDirective} from 'src/app/shared/directives/password.directive';
import {TruncatePipe} from 'src/app/shared/pipes/truncate.pipe';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {MustMatchDirective} from 'src/app/shared/directives/match.directive';
import {ScrollTrackerDirective} from 'src/app/shared/directives/scroll-traker.directive';
import {MaterialModule} from 'src/app/shared/material/material.module';
import {ScrollDirective} from 'src/app/shared/directives/scroll.directive';
import {BlockCopyDirective} from 'src/app/shared/directives/block-copy.directive';
import {ConvertNumberService} from 'src/app/shared/services/convert-number.service';
import {ConvertPersianNo} from 'src/app/shared/pipes/convert-number.pipe';
import { SwiperModule } from 'swiper/angular';
import { SidebarComponent } from 'src/app/shared/components/sidebar/sidebar.component';
import { NotFoundComponent } from 'src/app/shared/not-found/not-found.component';
import {ImageUploadComponent} from "src/app/shared/components/image-upload/image-upload.component";
import {ImageCropComponent} from "src/app/shared/components/image-crop/image-crop.component";
import { FormRadioComponent } from 'src/app/shared/components/forms/form-radio/form-radio.component';
import { FormCheckboxComponent } from 'src/app/shared/components/forms/form-checkbox/form-checkbox.component';
import { DatePickerComponent } from 'src/app/shared/components/forms/date-picker/date-picker.component';
import { CustomSingleSelectComponent } from 'src/app/shared/components/forms/custom-single-select/custom-single-select.component';
import { ConfirmComponent } from 'src/app/shared/components/confirm/confirm.component';
import {ListSearchComponent} from "src/app/shared/components/list-search/list-search.component";
import { SosRadioComponent } from 'src/app/shared/components/forms/sos-radio/sos-radio.component';
import { UploadFileComponent } from 'src/app/shared/components/forms/upload-file/upload-file.component';
import { UploadVideoComponent } from 'src/app/shared/components/forms/upload-video/upload-video.component';
import {MapModalComponent} from "src/app/shared/components/map-modal/map-modal.component";
import { MapComponent } from 'src/app/shared/components/map/map.component';
import { DialogComponent } from 'src/app/shared/components/dialog/dialog.component';
import { TranslateModule} from "@ngx-translate/core";
import {FormSelectComponent} from "src/app/shared/components/forms/form-select/form-select.component";
import { NormalSelectComponent } from 'src/app/shared/components/forms/normal-select/normal-select.component';
import { InputComponent } from 'src/app/shared/components/forms/input/input.component';
import { InputMatComponent } from 'src/app/shared/components/forms/input-mat/input-mat.component';
import { SelectMatComponent } from 'src/app/shared/components/forms/select-mat/select-mat.component';
import { RadioMatComponent } from 'src/app/shared/components/forms/radio-mat/radio-mat.component';
import {CheckboxMatComponent} from "src/app/shared/components/forms/checkbox-mat/checkbox-mat.component";
import {NgxMaskModule} from "ngx-mask";
import { TextFieldForArrayComponent } from 'src/app/shared/components/forms/text-field-for-array/text-field-for-array.component';
import { SelectWithChipsComponent } from './components/forms/select-with-chips/select-with-chips.component';
import {MatTableResponsiveDirective} from "./directives/mat-table-responsive.directive";

@NgModule({
  declarations: [
      PersianDate,
      PersianShortDate,
      PasswordShowHideDirective,
      TruncatePipe,
      MustMatchDirective,
      ScrollTrackerDirective,
      ScrollDirective,
      BlockCopyDirective,
      ConvertPersianNo,
      SidebarComponent,
      NotFoundComponent,
      ImageUploadComponent,
      ImageCropComponent,
      FormRadioComponent,
      FormCheckboxComponent,
      DatePickerComponent,
      CustomSingleSelectComponent,
      ConfirmComponent,
      ListSearchComponent,
      SosRadioComponent,
      UploadFileComponent,
      UploadVideoComponent,
      MapModalComponent,
      MapComponent,
      DialogComponent,
      TruncatePipe,
      ConvertPersianNo,
      BlockCopyDirective,
      MustMatchDirective,
      PasswordShowHideDirective,
      ScrollDirective,
      ScrollTrackerDirective,
      FormSelectComponent,
      NormalSelectComponent,
      InputComponent,
      InputMatComponent,
      SelectMatComponent,
      RadioMatComponent,
      CheckboxMatComponent,
      TextFieldForArrayComponent,
      SelectWithChipsComponent,
      MatTableResponsiveDirective,
  ],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        CoreModule,
        LeafletModule,
        ReactiveFormsModule,
        MaterialModule,
        ImageCropperModule,
        SwiperModule,
        RouterModule,
        NgbModule,
        TranslateModule,
        NgxMaskModule,
    ],
  exports: [
      PersianDate,
      PersianShortDate,
      MustMatchDirective,
      ScrollTrackerDirective,
      ReactiveFormsModule,
      FormsModule,
      MaterialModule,
      ScrollDirective,
      ImageCropperModule,
      BlockCopyDirective,
      ConvertPersianNo,
      NgbModule,
      TranslateModule,

      SidebarComponent,
      NotFoundComponent,
      ImageUploadComponent,
      ImageCropComponent,
      CustomSingleSelectComponent,
      FormCheckboxComponent,
      FormRadioComponent,
      DatePickerComponent,
      ConfirmComponent,
      ListSearchComponent,
      SosRadioComponent,
      UploadFileComponent,
      UploadVideoComponent,
      MapModalComponent,
      MapComponent,
      DialogComponent,
      FormSelectComponent,
      NormalSelectComponent,
      InputComponent,
      InputMatComponent,
      SelectMatComponent,
      RadioMatComponent,
      CheckboxMatComponent,
      TextFieldForArrayComponent,
      SelectWithChipsComponent,
      MatTableResponsiveDirective,
  ],
  providers: [
    ConvertNumberService,
  ]
})

export class SharedModule {
}
