import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-text-field-for-array',
  templateUrl: './text-field-for-array.component.html',
  styleUrls: ['./text-field-for-array.component.css']
})
export class TextFieldForArrayComponent implements OnInit {

  @Input() mask = '';
  @Input() type = 'text';
  @Input() isDrop = true;
  @Input() control: any;
  @Input() label = '';
  @Input() placeholder = '';
  @Input() controlName = '';
  @Input() form: FormGroup;

  constructor() {}

  ngOnInit(): void {
  }

}
