import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpEvent } from '@angular/common/http';
import { take, exhaustMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable()

export class AuthInterceptorService implements HttpInterceptor{
    constructor(private authService: AuthService) {}
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return this.authService.user.pipe(
            take(1),
            exhaustMap(user => {
                
                if(!user) {
                    return next.handle(req)
                }
                
                const modifiedReq = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${user.jwtToken}`
                    }
                });
                
                return next.handle(modifiedReq)
            })
        )
    }
}
